import { gql } from "@apollo/client";
import { ButtonBase, Collapse } from "@mui/material";
import { TEAM_COLORS } from "helpers/colors";
import useData from "helpers/useData";
import Splash from "molecules/Splash";
import Subnav, { SubnavDropdown, SubnavDropdownItem, SubnavIcon, SubnavToggleLabel } from "molecules/Subnav";
import React, { useState } from "react";

import TeamChnageLogo from "./TeamChangeLogo";

export default function TeamPagesHeader({ teamId, tab }) {
  const [data] = useData(
    gql`
      query TeamPagesHeader($teamId: ID!) {
        team(id: $teamId) {
          id
          name
          logoUrl
          colour
          lastLeague {
            id
            name
          }
          lastSeason {
            id
          }
        }
        currentUser {
          id
          teams {
            id
            name
            colour
            logoUrl
            lastLeague {
              id
              name
            }
          }
        }
      }
    `,
    { teamId },
  );

  const lastSeasonId = data?.team?.lastSeason?.id;
  const teamColor = TEAM_COLORS[data?.team?.colour || "red"];

  const [changingLogo, changingLogoSet] = useState(false);

  return (
    <>
      <Splash
        logoLoading={!data}
        loading={!data}
        logo={
          data?.team?.logoUrl && (
            <ButtonBase
              onClick={(event) => {
                event.preventDefault();
                changingLogoSet((c) => !c);
              }}
            >
              <img
                src={data.team.logoUrl}
                alt="Photo"
                data-test-team-logo
                style={{
                  width: 140,
                  height: 140,
                  objectFit: "contain",
                }}
              />
            </ButtonBase>
          )
        }
        color={teamColor}
        title={data?.team?.name}
        descriptionHeader="League"
        descriptionValue={data?.team?.lastLeague?.name}
      />
      <Collapse in={changingLogo}>
        <TeamChnageLogo teamId={teamId} open={changingLogo} onClose={() => changingLogoSet(false)} />
      </Collapse>
      <Subnav>
        {data?.currentUser?.teams.length > 1 && (
          <SubnavDropdown
            icon={<SubnavIcon src={data?.team?.logoUrl} />}
            text={data?.team?.name}
            description={data?.team?.lastLeague?.name}
          >
            {data?.currentUser?.teams.map((t) => (
              <SubnavDropdownItem
                icon={<SubnavIcon src={t.logoUrl} />}
                key={t.id}
                text={t.name}
                description={t.lastLeague?.name}
                selected={t.id === teamId}
                href={`/team/${t.id}`}
              >
                {t.name}
              </SubnavDropdownItem>
            ))}
          </SubnavDropdown>
        )}
        <SubnavToggleLabel text="Roster" checked={tab === "roster"} href={`/team/${teamId}/roster`} />
        <SubnavToggleLabel
          text="Games"
          checked={tab === "games"}
          disabled={!lastSeasonId}
          href={`/team/${teamId}/games?seasonId=${lastSeasonId}`}
        />
        <SubnavToggleLabel
          text="Leaderboard"
          checked={tab === "leaderboard"}
          disabled={!lastSeasonId}
          href={`/team/${teamId}/leaderboard?seasonId=${lastSeasonId}`}
        />
        <SubnavToggleLabel
          text="Performance"
          checked={tab === "performance"}
          disabled={!lastSeasonId}
          href={`/team/${teamId}/performance?seasonId=${lastSeasonId}`}
        />
        <SubnavToggleLabel
          text="Season"
          checked={tab === "season"}
          disabled={!lastSeasonId}
          href={`/team/${teamId}/season?seasonId=${lastSeasonId}`}
        />
      </Subnav>
    </>
  );
}
