import { CircularProgress } from "@mui/material";
import { COLOR_BLACK, COLOR_GREY, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import disableTouchForHover from "helpers/disableTouchForHover";
import { Play } from "mdi-material-ui";
import React, { createRef, useEffect, useState } from "react";
import { useComponentSize } from "react-use-size";

import HLSVideo from "./HLSVideo";

export default function VideoThumbnail({
  thumbnailUrl,
  processing = false,
  previewable = false,
  active = false,
  activeProgress = null,
  videoUrl,
  leftLabel = null,
  rightLabel = null,
  component: Component = "div",
  ...others
}) {
  const componentSize = useComponentSize();
  if (!videoUrl) previewable = false;
  const [hovering, hoveringSet] = useState(false);
  const [previewing, previewingSet] = useState(false);
  const [previewProgress, previewProgressSet] = useState(0);

  const videoRef = createRef();
  useEffect(() => {
    if (hovering && previewable) {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().catch(() => null);
      }
    } else {
      videoRef.current?.pause();
    }
  }, [hovering, previewable]);

  const circleSize = (componentSize.width + componentSize.height) * 0.1 || 30;

  return (
    <Component
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
      {...disableTouchForHover}
      {...others}
      style={{
        color: "inherit",
        textDecoration: "none",
        width: 120,
        aspectRatio: "3/2",
        position: "relative",
        overflow: "hidden",
        fontSize: 12,
        ...others.style,
      }}
    >
      {videoUrl && (
        <HLSVideo
          src={videoUrl}
          poster={thumbnailUrl}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
          }}
          videoRef={videoRef}
          muted
          loop
          playsInline
          disablePictureInPicture
          disableRemotePlayback
          preload="none"
          onPlaying={() => previewingSet(true)}
          onPause={() => previewingSet(false)}
          onTimeUpdateUnthrottled={
            previewing &&
            ((video) => {
              previewProgressSet(video.currentTime / video.duration);
            })
          }
        />
      )}
      {!videoUrl && !!thumbnailUrl && (
        <img
          src={thumbnailUrl}
          style={{
            pointerEvents: "none",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
            opacity: previewing ? 0 : 1,
          }}
        />
      )}
      <div
        ref={componentSize.ref}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          ...(!thumbnailUrl &&
            !videoUrl && {
              //
              backgroundColor: COLOR_GREY,
              color: COLOR_WHITE,
            }),
        }}
      >
        {!thumbnailUrl && !videoUrl && (
          <>
            {processing && (
              <>
                <CircularProgress size={20} color="inherit" />
                This video is still processing.
              </>
            )}
            {!processing && <> Sorry, this video is unavailable.</>}
          </>
        )}
        {(thumbnailUrl || videoUrl) && (
          <div
            style={{
              backgroundColor: COLOR_WHITE,
              color: COLOR_BLACK,
              // fancy CSS circle
              width: circleSize,
              height: circleSize,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: circleSize * 0.75,
              transition: "all ease .3s",
              position: "relative",
              ...(hovering && {
                transform: "scale(1.25)",
              }),
              ...((previewing || active) && {
                opacity: 0,
              }),
            }}
          >
            <Play fontSize="inherit" />
          </div>
        )}
      </div>
      {previewing && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: 5,
            width: `${(previewProgress || 0) * 100}%`,
            backgroundColor: COLOR_SECONDARY,
          }}
        />
      )}
      {active && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "100%",
            width: `${(activeProgress || 0) * 100}%`,
            backgroundColor: COLOR_WHITE,
            opacity: 0.8,
          }}
        />
      )}
      {leftLabel &&
        React.cloneElement(leftLabel, {
          style: {
            position: "absolute",
            bottom: 5,
            left: 5,
            ...leftLabel.props.style,
          },
        })}
      {rightLabel &&
        React.cloneElement(rightLabel, {
          style: {
            position: "absolute",
            bottom: 5,
            right: 5,
            ...rightLabel.props.style,
          },
        })}
    </Component>
  );
}
