const smallscreen = document.body.clientWidth < 600;

// difine spacing between any neibouring elements
export const SPACING = smallscreen ? "14px" : "20px";
export const SPACING_HALF = smallscreen ? "7px" : "10px";

// smaller spacing for elemenst inside text flow
export const SPACING_DENSE = smallscreen ? "6px" : "8px";
export const SPACING_DENSE_HALF = smallscreen ? "3px" : "4px";

// even smaller spacing for extreeeme space saving
export const SPACING_VERY_DENSE = "2px";
export const SPACING_VERY_DENSE_HALF = "2px";

// padding for the page content container
export const SPACING_PADDING = smallscreen ? "10px" : "20px";
