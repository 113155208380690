import { gql } from "@apollo/client";
import { alpha, ButtonBase, Collapse, Paper } from "@mui/material";
import defaultAvatarPng from "assets/default-avatar.png";
import overviewHeaderBgJpg from "assets/overview-header-bg.jpg";
import Spacer from "atoms/Spacer";
import VideoThumbnail from "atoms/VideoThumbnail";
import { COLOR_BLACK, COLOR_PRIMARY, COLOR_WHITE, getStatColor, TEAM_COLORS } from "helpers/colors";
import { PHOTO_CARD_ASPECT_RATIO } from "helpers/constants";
import { ONSEN_APP } from "helpers/environment";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING, SPACING_DENSE, SPACING_PADDING } from "helpers/spacings";
import useData from "helpers/useData";
import { Download } from "mdi-material-ui";
import Subnav, { SubnavDropdown, SubnavToggleLabel } from "molecules/Subnav";
import React from "react";
import { useComponentSize } from "react-use-size";

import GamePlayerSelector from "./GamePlayerSelector";

// These sizing are just locally for the header to contain layout, do not reuse.
const CONTENT_WIDTH = 1280;
const SIDE_CARD_WIDTH = 250;

export default function GameOverviewPageHeader({ gameId, gamePlayerId, gamePlayerIdSet, tab, tabSet }) {
  const [data] = useData(
    gql`
      query GameOverviewPageHeader($gameId: ID!) {
        game(id: $gameId) {
          id
          videoDownloadUrl
          videoProcessing
          thumbnailUrl
          homeScore
          home {
            id
            name
            colour
            logoUrl
          }
          awayScore
          away {
            id
            name
            colour
            logoUrl
          }
          thumbnailUrl
        }
      }
    `,
    { gameId },
  );

  const [dataGamePlayer] = useData(
    gql`
      query GameOverviewPageHeader_gamePlayer($gamePlayerId: ID!) {
        gamePlayer(id: $gamePlayerId) {
          id
          playerNumber
          team {
            id
            name
            logoUrl
            colour
          }
          reelViewsAny
          person {
            id
            fullNameWithAnonymisation
            photoCardUrl
          }
          overviewStats {
            name
            value
          }
        }
      }
    `,
    { gamePlayerId },
    { skip: !gamePlayerId },
  );

  const gamePlayer = dataGamePlayer?.gamePlayer;
  const color = TEAM_COLORS[gamePlayer?.team.colour || data?.game?.home.colour] || COLOR_PRIMARY;
  const componentSize = useComponentSize();

  return (
    <>
      <div
        ref={componentSize.ref}
        style={{
          background: [
            //
            `linear-gradient(to right, ${alpha(color, 0.94)}, ${alpha(color, 0.94)})`,
            `url(${overviewHeaderBgJpg})`,
          ].join(", "),
          color: COLOR_WHITE,
          fontWeight: "bold",
        }}
      >
        <div style={{ maxWidth: CONTENT_WIDTH, margin: "0 auto" }}>
          <div
            style={{
              padding: SPACING_PADDING,
              gap: SPACING,
              display: "flex",
              alignItems: "center",
              justifyContent: "stretch",
              flexFlow: "row nowrap",
              fontSize: "min(3vw, 30px)",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              textTransform: "uppercase",
            }}
          >
            <img src={data?.game?.home.logoUrl} alt={data?.game?.home.name} style={{ width: "3em" }} />
            <div style={{ flex: "1 1 80em" }}>{data?.game?.home.name}</div>
            <div style={{ fontSize: "2em" }}>{data?.game?.homeScore}</div>
            <div style={{ fontSize: 10, opacity: 0.5 }}>VS</div>
            <div style={{ fontSize: "2em" }}>{data?.game?.awayScore}</div>
            <div
              style={{
                flex: "1 1 80em",
                textAlign: "right",
              }}
            >
              {data?.game?.away.name}
            </div>
            <img src={data?.game?.away.logoUrl} alt={data?.game?.away.name} style={{ width: "3em" }} />
          </div>
          <Subnav transparent>
            <SubnavDropdown
              text={gamePlayer && `${gamePlayer.person.fullNameWithAnonymisation} #${gamePlayer.playerNumber}`}
              disabled={!gamePlayer}
            >
              <GamePlayerSelector gameId={gameId} gamePlayerId={gamePlayerId} gamePlayerIdSet={gamePlayerIdSet} />
            </SubnavDropdown>
            <SubnavToggleLabel text="Overview" checked={!tab} onClick={() => tabSet(null)} />
            <SubnavToggleLabel text="Highlight reels" checked={tab === "reels"} onClick={() => tabSet("reels")} />
            <SubnavToggleLabel text="Highlights" checked={tab === "highlights"} onClick={() => tabSet("highlights")} />
            <SubnavToggleLabel text="Shot chart" checked={tab === "shotchart"} onClick={() => tabSet("shotchart")} />
            <SubnavToggleLabel text="Box score" checked={tab === "boxscore"} onClick={() => tabSet("boxscore")} />
            <SubnavToggleLabel text="Point chart" checked={tab === "pointchart"} onClick={() => tabSet("pointchart")} />
            <Spacer />
            {!ONSEN_APP && (
              <SubnavToggleLabel
                icon={<Download />}
                tooltip="Download Game Video"
                target="_blank"
                disabled={!data?.game?.videoDownloadUrl}
                href={data?.game?.videoDownloadUrl}
              />
            )}
          </Subnav>
          <Collapse in={!tab}>
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignItems: "stretch",
                padding: SPACING_PADDING,
                gap: SPACING,
              }}
            >
              {componentSize.width > 1000 && (
                <Paper
                  style={{
                    //
                    width: SIDE_CARD_WIDTH,
                    flex: "0 0 auto",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: SPACING_DENSE,
                  }}
                >
                  <img
                    src={gamePlayer?.person.photoCardUrl || defaultAvatarPng}
                    alt={gamePlayer?.person.fullNameWithAnonymisation}
                    style={{
                      width: "100%",
                      aspectRatio: PHOTO_CARD_ASPECT_RATIO,
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              )}
              <ButtonBase
                href={`/game/${gameId}/video`}
                style={{
                  flex: "1 1 auto",
                  minHeight: SIDE_CARD_WIDTH,
                  position: "relative",
                }}
              >
                <VideoThumbnail
                  component={Paper}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  processing={data?.game?.videoProcessing}
                  thumbnailUrl={data?.game?.thumbnailUrl}
                />
              </ButtonBase>

              {componentSize.width > 640 && (
                <Paper
                  style={{
                    //
                    width: SIDE_CARD_WIDTH,
                    flex: "0 0 auto",
                    display: "flex",
                    flexFlow: "column nowrap",
                    justifyContent: "stretch",
                  }}
                >
                  {gamePlayer?.overviewStats.map((stat) => (
                    <div
                      key={stat.name}
                      style={{
                        flex: "1 1 auto",
                        padding: SPACING_DENSE,
                        borderBottom: `1px solid ${alpha(COLOR_BLACK, 0.1)}`,
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "stretch",
                        alignItems: "center",
                        gap: SPACING_DENSE,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: getStatColor(stat.name),
                          color: COLOR_WHITE,
                          paddingLeft: SPACING_DENSE,
                          paddingRight: SPACING_DENSE,
                          borderRadius: SPACING_DENSE,
                          width: "2em",
                          height: "2em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {stat.name[0]}
                      </div>
                      <div style={{ flex: "1 1 auto" }}>{stat.name}</div>
                      {stat.value ? (
                        <div
                          style={{
                            color: getStatColor(stat.name),
                            fontWeight: "bold",
                          }}
                        >
                          {stat.value}
                        </div>
                      ) : (
                        <ButtonBase
                          href={`/game/${gameId}/video`}
                          style={{
                            padding: "4px 8px",
                            fontSize: 10,
                            border: "1px solid",
                            opacity: 0.5,
                            borderRadius: 8,
                          }}
                        >
                          Tag {stat.name}
                        </ButtonBase>
                      )}
                    </div>
                  ))}
                </Paper>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
}
