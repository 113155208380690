import { gql } from "@apollo/client";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import PageTitle from "shared/PageTitle";

import HighlightPlayer, { HighlightPlayerFragment } from "./video_player/HighlightPlayer";

export default function EventPage({ eventViewId }) {
  const [data] = useData(
    gql`
      query EventPage($eventViewId: ID!) {
        eventView(id: $eventViewId) {
          id
          title
          club {
            id
          }
          ...HighlightPlayerFragment
        }
      }
      ${HighlightPlayerFragment}
    `,
    {
      eventViewId,
    },
  );
  if (data && !data.eventView) return <NotFoundPage />;
  gaSetSharedEventParams({ club_id: data?.eventView?.club?.id });

  return (
    <>
      <PageTitle title="Highlight" />
      <HighlightPlayer eventView={data?.eventView} eventViewId={eventViewId} />
    </>
  );
}
