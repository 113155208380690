import { gql } from "@apollo/client";
import { alpha, LinearProgress } from "@mui/material";
import { ButtonBase } from "@mui/material";
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_DARKER,
  COLOR_SECONDARY,
  COLOR_SECONDARY_DARKER,
  COLOR_WHITE,
} from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { Star } from "mdi-material-ui";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import React, { useEffect, useRef } from "react";

export const PotwEntryButtonFragment = gql`
  fragment PotwEntryButtonFragment on PotwEntry {
    id
    rating
    rank
    title
    subtitle
    eventView {
      id
      ...EventAvatarFragment
    }
  }
  ${EventAvatarFragment}
`;

export default function PotwEntryButton({ potwEntry, active = false, playProgress = null, ...others }) {
  const ref = useRef();
  useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [active]);

  return (
    <ButtonBase
      ref={ref}
      {...others}
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        gap: 10,
        padding: "10px 20px",
        cursor: "pointer",
        borderBottom: `1px solid ${alpha(COLOR_WHITE, 0.1)}`,
        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
        backgroundColor: active ? COLOR_PRIMARY : COLOR_PRIMARY_DARKER,
        ...(potwEntry?.rank <= 5 && {
          backgroundColor: active ? COLOR_SECONDARY : COLOR_SECONDARY_DARKER,
        }),
        position: "relative",
        ...others.style,
      }}
    >
      <div
        style={{
          fontStyle: "italic",
        }}
      >
        <span style={{ opacity: 0.3 }}>{"#"}</span>
        <span
          style={{
            opacity: 0.5,
            ...(potwEntry?.rank <= 5 && {
              opacity: 1,
              fontSize: "1.8em",
            }),
          }}
        >
          {potwEntry?.rank}
        </span>
      </div>
      <EventAvatar style={{ flex: "0 0 auto" }} inverse active={active} size={32} eventView={potwEntry?.eventView} />
      <div
        style={{
          flex: "1 1 200px",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          gap: 5,
        }}
      >
        <div>{potwEntry.title}</div>
        <div
          style={{
            fontSize: ".9em",
            opacity: 0.6,
            textTransform: "uppercase",
            fontWeight: 200,
          }}
        >
          {potwEntry.subtitle}
        </div>
      </div>
      <Star
        fontSize="inherit"
        style={{
          color: potwEntry?.rank <= 5 ? COLOR_WHITE : COLOR_SECONDARY,
        }}
      />
      <div>{potwEntry.rating}</div>
      {playProgress !== null && !Number.isNaN(playProgress) && (
        <LinearProgress
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
          }}
          color="inherit"
          variant="determinate"
          value={playProgress * 100}
        />
      )}
    </ButtonBase>
  );
}
