import "./index.css";
import "./styles/progressbar-spinner.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/pathway-gothic-one/index.css";
import "@fontsource-variable/league-gothic/standard.css";
import "helpers/rollbar";

import App from "App";
import React from "react";
import { createRoot } from "react-dom/client";

const element = document.getElementById("root");
const root = createRoot(element);
root.render(<App />);
// eslint-disable-next-line no-console
console.log("Hello, world!");
