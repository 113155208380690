import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import { TEAM_COLORS } from "helpers/colors";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import Section from "molecules/Section";
import React from "react";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TeamPageTitle } from "shared/PageTitle";

import TeamPagesHeader from "./team/TeamPagesHeader";
import TeamSeasonSelectSubnav from "./team/TeamSeasonSelectSubnav";

export default function TeamSeasonPage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });
  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [data] = useData(
    gql`
      query TeamSeasonPage($teamId: ID!, $seasonId: ID!) {
        team(id: $teamId) {
          id
          name
          colour
          seasonGraphEntries(seasonId: $seasonId) {
            playedOn
            score
            againstTeamScore
            againstTeam {
              id
              name
            }
          }
        }
      }
    `,
    { teamId, seasonId },
  );
  const teamColor = TEAM_COLORS[data?.team?.colour];

  return (
    <>
      <TeamPageTitle title="Season" />
      <TeamPagesHeader teamId={teamId} tab="season" />
      <TeamSeasonSelectSubnav teamId={teamId} seasonId={seasonId} seasonIdSet={seasonIdSet} />
      <PageContentStack>
        <Section title="Season Graph">
          {data?.team && (
            <div style={{ height: "50vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data.team.seasonGraphEntries.map((entry) => ({
                    ...entry,
                    title: `vs ${entry.againstTeam.name}`,
                  }))}
                >
                  <XAxis dataKey="title" angle={20} />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#aaa" />
                  <Bar name="Team Score" dataKey="score" fill={teamColor}>
                    <LabelList dataKey="score" position="top" />
                  </Bar>
                  <Bar name="Opponent Score" dataKey="againstTeamScore" fill="#ccc">
                    <LabelList dataKey="againstTeamScore" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </Section>
      </PageContentStack>
    </>
  );
}
