import { gql } from "@apollo/client";
import { Button, DialogActions, Paper, TextField } from "@mui/material";
import { gaEvent } from "helpers/gtag";
import useAction from "helpers/useAction";
import useData from "helpers/useData";
import React, { useEffect, useState } from "react";

export default function NotesDropdown({ clipId, ...others }) {
  const [data] = useData(
    gql`
      query NotesDropdown($clipId: ID!) {
        clip(id: $clipId) {
          id
          description
        }
      }
    `,
    { clipId },
  );

  const clipUpdate = useAction(
    gql`
      mutation ClipUpdate($id: ID!, $description: String) {
        clipUpdate(input: { id: $id, description: $description }) {
          clip {
            id
            description
            hasDescription
          }
        }
      }
    `,
    ({ id, description }) => ({
      optimisticResponse: {
        clipUpdate: {
          clip: {
            id,
            __typename: "Clip",
            description,
            hasDescription: !!description,
          },
        },
      },
    }),
  );

  const [text, textSet] = useState("");

  useEffect(() => {
    textSet(data?.clip.description || "");
  }, [clipId, !!data]);

  return (
    <Paper elevation={6} square {...others}>
      <TextField
        disabled={!data}
        style={{
          width: 300,
          margin: 10,
        }}
        variant="standard"
        placeholder="Add some notes about this clip..."
        multiline
        minRows={5}
        value={text}
        onChange={(e) => textSet(e.target.value)}
      />
      {data && text !== data.clip.description && (
        <DialogActions style={{ marginTop: 0 }}>
          <Button
            onClick={() => {
              clipUpdate({ id: clipId, description: text });
              gaEvent("playlist_clip_notes", {
                action: "confirm",
              });
            }}
          >
            Save
          </Button>
          <Button onClick={() => textSet(data.clip.description)} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      )}
    </Paper>
  );
}
