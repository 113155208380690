import { gql } from "@apollo/client";
import { DarkMode, LightMode, SettingsBrightness } from "@mui/icons-material";
import {
  ButtonBase,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import logoSvg from "assets/logo.svg";
import Spacer from "atoms/Spacer";
import Stack from "atoms/Stack";
import apolloClient from "helpers/apolloClient";
import { COLOR_PRIMARY } from "helpers/colors";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import rollbar from "helpers/rollbar";
import { SPACING, SPACING_DENSE, SPACING_HALF } from "helpers/spacings";
import useData from "helpers/useData";
import usePlaylistsEnabled from "helpers/usePlaylistsEnabled";
import { progressBar } from "helpers/useProgressBar";
import {
  AccountCircle,
  AccountCog,
  AccountGroup,
  AccountMultiple,
  Basketball,
  ClipboardPlayMultiple,
  Logout,
  Refresh,
  SwapHorizontal,
  Trophy,
} from "mdi-material-ui";
import { uiSettingsContext } from "providers/UISettingsProvider";
import React, { cloneElement, createContext, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useComponentSize } from "react-use-size";

import { pageContext } from "./Page";

const navbarContext = createContext();

export default function Navbar() {
  const [data] = useData(gql`
    query NavbarCurrentUser {
      currentUser {
        id
        onboarded
        isPublicLeaguePersona
        potwEnabled
        canAccessReadmin
        email
      }
      readminUrl
    }
  `);
  const componentSize = useComponentSize();
  const navbarWidth = componentSize.width;
  const playlistsEnabled = usePlaylistsEnabled();
  const currentUserId = data?.currentUser?.id;
  const currentUserEmail = data?.currentUser?.email;

  useEffect(() => {
    rollbar.configure({
      payload: {
        person: {
          id: currentUserId,
          email: currentUserEmail,
        },
      },
    });
  }, [currentUserId, currentUserEmail]);
  const { navbarLeft } = useContext(pageContext);
  const { uiMode, uiModeSet } = useContext(uiSettingsContext);

  return (
    <navbarContext.Provider value={{ navbarWidth }}>
      <Stack
        noGap
        ref={componentSize.ref}
        horizontal={!navbarLeft}
        style={{
          background: COLOR_PRIMARY, //standard navbar colour
          color: "white",
          ...(navbarLeft
            ? {
                paddingLeft: "env(safe-area-inset-left)",
              }
            : {
                paddingTop: "env(safe-area-inset-top)",
              }),
        }}
      >
        {data?.currentUser && (
          <>
            <HomeLogo />
            <NavbarItem label="Games" icon={<Basketball />} href="/games" />
            <NavbarItem label="Teams" icon={<AccountGroup />} href="/teams" />
            {data?.currentUser?.potwEnabled && !data.currentUser.isPublicLeaguePersona && (
              <NavbarItem label="Plays of the Week" icon={<Trophy />} href="/potw" />
            )}
            {playlistsEnabled && <NavbarItem label="Playlists" icon={<ClipboardPlayMultiple />} href="/playlists" />}
            <Spacer />
            <IconButton
              className="progressbar-spinner"
              color="inherit"
              onClick={() => progressBar(() => apolloClient.refetchQueries({ include: "active" }))}
            >
              <Refresh />
            </IconButton>
          </>
        )}
        {data?.currentUser && !data?.currentUser?.isPublicLeaguePersona && (
          <>
            <NavbarMenu icon={<AccountCircle />} label={data.currentUser.email}>
              <Stack padding dense>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={uiMode}
                  onChange={(_, value) => value && uiModeSet(value)}
                >
                  <ToggleButton value="light">
                    <LightMode fontSize="inherit" />
                    Light
                  </ToggleButton>
                  <ToggleButton value="system">
                    <SettingsBrightness fontSize="inherit" />
                    System
                  </ToggleButton>
                  <ToggleButton value="dark">
                    <DarkMode fontSize="inherit" />
                    Dark
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              {data.currentUser.canAccessReadmin && (
                <NavbarMenuItem icon={<SwapHorizontal />} label="Switch to Admin Console" href={data.readminUrl} />
              )}
              <NavbarMenuItem label="My Profiles" icon={<AccountMultiple />} href="/people" />
              <NavbarMenuItem
                label={data?.currentUser?.onboarded ? "My Account & Settings" : "Set up your Account"}
                icon={<AccountCog />}
                href="/account"
              />
              <NavbarMenuItem
                label="Logout"
                icon={<Logout />}
                onClick={() =>
                  progressBar(async () => {
                    window.localStorage.removeItem(ONSEN_AUTH_TOKEN_KEY);
                    await apolloClient.resetStore();
                  })
                }
              />
            </NavbarMenu>
          </>
        )}
      </Stack>
    </navbarContext.Provider>
  );
}

function HomeLogo() {
  const { navbarWidth } = useContext(navbarContext);
  const [data] = useData(gql`
    query HomeLogo {
      currentUser {
        id
        isPublicLeaguePersona
        publicLeagueTitle
        publicLeagueLogo
      }
    }
  `);

  return (
    <a
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontSize: 16,
        padding: SPACING_HALF,
        gap: SPACING,
        textDecoration: "none",
        color: "inherit",
      }}
      href="/"
    >
      {data?.currentUser?.isPublicLeaguePersona ? (
        <>
          <img
            src={data?.currentUser.publicLeagueLogo}
            style={{
              flex: "none",
              width: 50,
            }}
          />
          {navbarWidth > 1000 && (
            <span
              style={{
                fontSize: "1.1em",
                flex: "auto",
                fontWeight: "bold",
              }}
            >
              {data?.currentUser.publicLeagueTitle}
            </span>
          )}
        </>
      ) : (
        <img
          src={logoSvg}
          style={{
            width: 24,
            height: 24,
          }}
          alt="Glory League Logo"
        />
      )}
    </a>
  );
}

function NavbarItem({ label, href, icon = null }) {
  const { navbarWidth } = useContext(navbarContext);
  const [hovering, hoveringSet] = useState(false);
  return (
    <>
      <ButtonBase
        focusRipple
        onMouseEnter={() => hoveringSet(true)}
        onMouseLeave={() => hoveringSet(false)}
        style={{
          outline: "initial",
          display: "flex",
          alignItems: "center",
          padding: SPACING_HALF,
          gap: SPACING_DENSE,
          fontSize: 14,
          flex: "initial",
        }}
        href={href}
      >
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.5em",
              ...(hovering && {
                opacity: 0.8,
              }),
            },
          })}
        {navbarWidth > 600 && (
          <span
            style={{
              fontWeight: 500,
              ...(hovering && {
                opacity: 0.8,
              }),
            }}
          >
            {label}
          </span>
        )}
      </ButtonBase>
    </>
  );
}

const navMenuContext = createContext();

function NavbarMenu({ icon, label, children }) {
  const { navbarWidth } = useContext(navbarContext);
  const [open, openSet] = useState(false);
  const ref = useRef();

  const { navbarLeft } = useContext(pageContext);

  return (
    <navMenuContext.Provider value={{ open, openSet }}>
      <ButtonBase
        ref={ref}
        focusRipple
        style={{
          display: "flex",
          padding: SPACING_HALF,
          alignItems: "center",
          flex: "none",
          fontSize: "14px",
          cursor: "pointer",
          gap: SPACING_DENSE,
        }}
        onClick={() => openSet(true)}
      >
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.6em",
            },
          })}
        {/* probably a tablet */}
        {navbarWidth > 1000 && label}
      </ButtonBase>
      <Menu
        variant="menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => openSet(false)}
        MenuListProps={{ disablePadding: true }}
        {...(navbarLeft
          ? {
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              transformOrigin: { vertical: "bottom", horizontal: "left" },
            }
          : {
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              transformOrigin: { vertical: "top", horizontal: "right" },
            })}
      >
        {children}
      </Menu>
    </navMenuContext.Provider>
  );
}

const NavbarMenuItem = forwardRef(function NavbarMenuItem({ label, icon, href, onClick }, ref) {
  const { openSet } = useContext(navMenuContext);
  return (
    <MenuItem
      ref={ref}
      style={{ fontSize: "14px" }}
      component="a"
      href={href || "#"}
      onClick={(event) => {
        onClick?.(event);
        openSet(false);
      }}
    >
      <ListItemIcon>
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.3em",
              color: "rgba(white, .4)",
            },
          })}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ fontSize: "1em" }}>{label}</ListItemText>
    </MenuItem>
  );
});
