import { gql } from "@apollo/client";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import FormDialog from "molecules/FormDialog";
import React, { useEffect, useState } from "react";

export default function EditPlaylistDialog({ open, onClose, playlistId }) {
  const playlistUpdate = useActionFragment("playlistUpdate", `playlist { id name isPublic playlistType description }`);
  const [data] = useData(
    gql`
      query EditPlaylistDialogQuery($playlistId: ID!) {
        playlist(id: $playlistId) {
          id
          name
          description
          playlistType
        }
      }
    `,
    { playlistId },
    { skip: !open || !playlistId },
  );

  const [name, nameSet] = useState("");
  const [description, descriptionSet] = useState("");
  const [shared, sharedSet] = useState(false);
  const showMessage = useShowMessage();
  let originalSharedValue = null;

  useEffect(() => {
    if (data && open) {
      nameSet(data.playlist.name);
      descriptionSet(data.playlist.description);
      sharedSet(data.playlist.playlistType === "shared");
      originalSharedValue = data.playlist.playlistType === "shared";
    }
  }, [data, open]);

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      title="Update Playlist"
      onSubmit={async () => {
        await playlistUpdate({
          input: {
            playlistId,
            name,
            description,
            shared,
          },
        });
        showMessage(`Playlist ${name} updated.`);
        if (shared != originalSharedValue) {
          gaEvent("playlist_share", {
            method: shared ? "share" : "unshare",
            action: "confirm",
          });
        }
      }}
    >
      <TextField
        autoFocus
        label="Playlist name"
        value={name}
        onChange={(event) => nameSet(event.target.value)}
        required
      />
      <TextField
        label="Description"
        value={description}
        onChange={(event) => descriptionSet(event.target.value)}
        multiline
        rows={5}
      />
      <FormControlLabel
        label="Public playlist"
        control={<Checkbox checked={shared} onChange={(event) => sharedSet(event.target.checked)} />}
      />
    </FormDialog>
  );
}
