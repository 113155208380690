import { gql } from "@apollo/client";
import { ButtonBase } from "@mui/material";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { FileTableOutline } from "mdi-material-ui";
import Dialog from "molecules/Dialog";
import StatsTable, {
  StatsTableHeaderCell,
  StatsTableRow,
  StatsTableStatCell,
  StatsTableStatCellFragment,
} from "molecules/StatsTable";
import React, { useState } from "react";

export const VideoBoxScoreButtonFragment = gql`
  fragment VideoBoxScoreButtonFragment on Game {
    currentUserDefaultGamePlayer {
      id
      team {
        id
      }
    }
    participations {
      id
      team {
        id
        name
      }
      gamePlayers {
        id
        playerNumber
        person {
          id
          fullNameWithAnonymisation
        }
        boxScoreStats {
          shortName
          name
          ...StatsTableStatCellFragment
        }
      }
    }
  }
  ${StatsTableStatCellFragment}
`;

export default function VideoBoxScoreButton({ game }) {
  const [open, openSet] = useState(false);
  const [teamId, teamIdSet] = useState();
  const currentParticipation = teamId
    ? game?.participations.find((participation) => participation.team.id === teamId)
    : game?.participations.find((participation) => participation.team.id === game.currentUserDefaultGamePlayer.team.id);
  const firstRow = currentParticipation?.gamePlayers[0];

  return (
    <>
      <Stack padding dense horizontal alignItemsCenter element={<ButtonBase onClick={() => openSet(true)} />}>
        <FileTableOutline />
        <div>Box Score</div>
      </Stack>
      <Dialog open={open} onClose={() => openSet(false)} inverse mediumWidth>
        <Stack>
          <Stack horizontal>
            {game?.participations.map((participation) => (
              <ButtonBase
                style={{
                  fontSize: "1.5em",
                  textTransform: "uppercase",
                  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                  opacity: participation.team.id === currentParticipation?.team.id ? 1 : 0.5,
                }}
                onClick={() => teamIdSet(participation.team.id)}
                key={participation.id}
              >
                {participation.team.name}
              </ButtonBase>
            ))}
          </Stack>
          <StatsTable
            inverse
            headerColumns={["Players"]}
            columns={firstRow?.boxScoreStats.map((columnStat) => (
              <WithTooltip tooltip={columnStat.name} key={columnStat.shortName}>
                {columnStat.shortName}
              </WithTooltip>
            ))}
          >
            {currentParticipation?.gamePlayers.map((gamePlayer) => (
              <StatsTableRow key={gamePlayer.id}>
                <StatsTableHeaderCell>
                  {gamePlayer.playerNumber} - {gamePlayer.person.fullNameWithAnonymisation}
                </StatsTableHeaderCell>
                {gamePlayer.boxScoreStats.map((stat) => (
                  <StatsTableStatCell key={stat.shortName} stat={stat} />
                ))}
              </StatsTableRow>
            ))}
          </StatsTable>
        </Stack>
      </Dialog>
    </>
  );
}
