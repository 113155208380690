import { gql } from "@apollo/client";
import FetchMoreButton from "atoms/FetchMoreButton";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import React from "react";
import { TeamPageTitle } from "shared/PageTitle";

import GameEntry, { GameEntryFragment } from "./games/GameEntry";
import TeamPagesHeader from "./team/TeamPagesHeader";
import TeamSeasonSelectSubnav from "./team/TeamSeasonSelectSubnav";

export default function TeamGamesPage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [data, dataMeta] = useData(
    gql`
      query TeamGamesPage($teamId: ID!, $seasonId: ID, $offset: Int) {
        team(id: $teamId) {
          id
        }
        currentUser {
          id
          gamesCount(teamId: $teamId, seasonId: $seasonId)
          games(teamId: $teamId, seasonId: $seasonId, offset: $offset, limit: 10) {
            id
            ...GameEntryFragment
          }
        }
      }
      ${GameEntryFragment}
    `,
    { teamId, seasonId },
  );

  return (
    <>
      <TeamPageTitle title="Games" />
      <TeamPagesHeader teamId={teamId} tab="games" />
      <TeamSeasonSelectSubnav teamId={teamId} seasonId={seasonId} seasonIdSet={seasonIdSet} allowAllSeasons />
      <PageContentStack>
        {data?.currentUser?.games.map((game) => (
          <GameEntry key={game.id} game={game} />
        ))}
        <FetchMoreButton
          dataMeta={dataMeta}
          rows={data?.currentUser?.games}
          rowsCount={data?.currentUser?.gamesCount}
        />
      </PageContentStack>
    </>
  );
}
