import { gql } from "@apollo/client";
import { COLOR_BLACK } from "helpers/colors";
import useData from "helpers/useData";
import useFullscreenState from "helpers/useFullscreenState";
import Dialog from "molecules/Dialog";
import React from "react";

import PotwVideoPlayer, { PotwVideoPlayerFragment } from "./PotwVideoPlayer";

export default function PotwVideoPlayerDialog({ open, onClose, potwCompetitionId }) {
  const [data] = useData(
    gql`
      query PotwVideoPlayerDialog($potwCompetitionId: ID!) {
        potwCompetition(id: $potwCompetitionId) {
          id
          ...PotwVideoPlayerFragment
        }
      }
      ${PotwVideoPlayerFragment}
    `,
    { potwCompetitionId },
    { skip: !open },
  );
  const [fullscreen] = useFullscreenState();

  return (
    <Dialog
      noPadding
      open={open}
      onClose={onClose}
      inverse
      {...(fullscreen
        ? { fullHeight: true, fullWidth: true }
        : {
            mediumWidth: true,
          })}
      PaperProps={{
        style: {
          backgroundColor: COLOR_BLACK,
        },
      }}
    >
      <PotwVideoPlayer potwCompetition={data?.potwCompetition} potwCompetitionId={potwCompetitionId} />
    </Dialog>
  );
}
