import { ButtonBase, ClickAwayListener, Fade, Paper, Popper, Skeleton } from "@mui/material";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_BLACK, COLOR_BLACK_LIGHTER, COLOR_GREY_BORDER, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_PATHWAY_GOTHIC_ONE } from "helpers/FONT";
import { SPACING_DENSE } from "helpers/spacings";
import usePseudoStyle from "helpers/usePseudoStyle";
import { defer } from "lodash-es";
import { MenuDown } from "mdi-material-ui";
import React, { createContext, useContext, useId, useRef, useState } from "react";

const subnavContext = createContext();

export default function Subnav({ children, secondary = false, transparent = false }) {
  return (
    <subnavContext.Provider value={{ secondary, transparent }}>
      <Stack
        horizontal
        padding
        dense
        data-test-subnav
        style={{
          minHeight: 42,
          color: COLOR_WHITE,
          backgroundColor: COLOR_BLACK,
          ...(secondary && {
            backgroundColor: COLOR_BLACK_LIGHTER,
          }),
          ...(transparent && {
            backgroundColor: "transparent",
          }),
        }}
      >
        {children}
      </Stack>
    </subnavContext.Provider>
  );
}

export function SubnavToggleLabel({ icon, endIcon, text, tooltip, checked = false, disabled = false, ...others }) {
  const { className } = usePseudoStyle({
    ":hover": `color: ${COLOR_BLACK} !important; background-color: ${COLOR_WHITE} !important;`,
  });

  return (
    <WithTooltip
      tooltip={tooltip}
      element={
        <ButtonBase
          className={className}
          style={{
            paddingBottom: 0,
            paddingLeft: SPACING_DENSE,
            paddingRight: SPACING_DENSE,
            border: `solid 1px ${COLOR_GREY_BORDER}`,
            borderRadius: 15,
            fontSize: 12,
            color: checked ? COLOR_BLACK : COLOR_WHITE,
            backgroundColor: checked ? COLOR_WHITE : "transparent",
            ...(disabled && {
              opacity: 0.5,
            }),
            ...others,
          }}
          {...others}
          disabled={disabled}
        >
          {icon}
          {text}
          {endIcon}
        </ButtonBase>
      }
    />
  );
}

export function SubnavDropdown({ icon, text, children, endIcon }) {
  const popperId = useId();
  const ref = useRef(null);
  const [open, openSet] = useState(false);
  const { transparent } = useContext(subnavContext);

  return (
    <>
      <ButtonBase
        ref={ref}
        color="inherit"
        href="#"
        onClick={() => openSet(true)}
        style={{
          // Dropdown doesn't have gutter, negate the padding / gap
          margin: `-${SPACING_DENSE} 0 -${SPACING_DENSE} -${SPACING_DENSE}`,
          padding: SPACING_DENSE,
          fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
          gap: SPACING_DENSE,
        }}
      >
        {icon}
        {text}
        {!text && <Skeleton style={{ backgroundColor: "#aaa", width: 80 }} />}
        {endIcon}
        <MenuDown
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-out",
          }}
        />
      </ButtonBase>
      <Popper id={popperId} anchorEl={ref.current} open={open} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <div>
              <ClickAwayListener onClickAway={() => openSet(false)}>
                <Paper
                  square
                  onClick={() => defer(() => openSet(false))}
                  style={{
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_BLACK,
                    display: "flex",
                    flexFlow: "column nowrap",
                    ...(transparent && {
                      backgroundColor: COLOR_WHITE,
                      color: COLOR_BLACK,
                    }),
                  }}
                  {...(transparent && {
                    square: false,
                  })}
                >
                  {children}
                </Paper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export function SubnavDropdownItem({ icon, text, description, selected = false, href, onClick }) {
  const [hovering, hoveringSet] = useState(false);

  return (
    <ButtonBase
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        gap: SPACING_DENSE,
        padding: SPACING_DENSE,
        fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
        ...((selected || hovering) && {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        }),
      }}
      href={href || "#"}
      onClick={(event) => onClick?.(event)}
    >
      {icon}
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          maxWidth: 200,
        }}
      >
        <div
          style={{
            fontWeight: 400,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
        <div
          style={{
            opacity: 0.3,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {description}
        </div>
      </div>
    </ButtonBase>
  );
}

export function SubnavIcon({ src, alt = "Icon" }) {
  if (!src) return <Skeleton variant="circular" style={{ width: 20, height: 20, backgroundColor: "#aaa" }} />;
  return <img src={src} alt={alt} style={{ width: 20, aspectRatio: "1/1", objectFit: "contain" }} />;
}
