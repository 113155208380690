import { gql } from "@apollo/client";
import Grid from "atoms/Grid";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import Message from "molecules/Message";
import TeamCard, { TeamCardFragment } from "molecules/TeamCard";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import { PersonPageTitle } from "shared/PageTitle";

import PersonPagesHeader from "./person/PersonPagesHeader";

export default function PersonTeamsPage({ personId }) {
  const [data] = useData(
    gql`
      query PersonTeamsPage($personId: ID!) {
        person(id: $personId) {
          id
          teams {
            id
            ...TeamCardFragment
          }
        }
      }
      ${TeamCardFragment}
    `,
    { personId },
  );

  if (data && !data.person) return <NotFoundPage />;

  return (
    <>
      <PersonPageTitle title="Teams" />
      <PersonPagesHeader personId={personId} tab="teams" />
      <PageContentStack>
        {data?.person?.teams.length === 0 && <Message>This person is not a member of any teams.</Message>}
        <Grid gridItemWidth="600px">
          {data?.person?.teams.map((team) => (
            <TeamCard key={team.id} team={team} personId={personId} />
          ))}
        </Grid>
      </PageContentStack>
    </>
  );
}
