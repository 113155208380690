import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "helpers/colors";
import React, { useContext, useMemo } from "react";

import { uiSettingsContext } from "./UISettingsProvider";

export default function MuiProvider({ children }) {
  const { isDarkMode } = useContext(uiSettingsContext);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            main: COLOR_PRIMARY,
          },
          secondary: {
            main: COLOR_SECONDARY,
          },
        },
      }),
    [isDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
