import React, { createContext, useEffect, useState } from "react";

export const uiSettingsContext = createContext();

const SAVE_UI_MODE_KEY = "gloryleague_ui_mode";

export default function UISettingsProvider({ children }) {
  const isSystemDarkMode = !!window.matchMedia?.("prefers-color-scheme: dark").matches;
  const [uiMode, uiModeSet] = useState(() => {
    let uiMode = window.localStorage.getItem(SAVE_UI_MODE_KEY);
    if (!["dark", "light", "system"].includes(uiMode)) uiMode = "system";
    return uiMode;
  });

  useEffect(() => {
    window.localStorage.setItem(SAVE_UI_MODE_KEY, uiMode);
  }, [uiMode]);

  const isDarkMode = uiMode === "dark" || (uiMode === "system" && isSystemDarkMode);

  return (
    <uiSettingsContext.Provider value={{ isDarkMode, uiMode, uiModeSet, isSystemDarkMode }}>
      {children}
    </uiSettingsContext.Provider>
  );
}
