import { gql } from "@apollo/client";
import { ONSEN_URL } from "helpers/environment";
import { gaEvent } from "helpers/gtag";
import isUniqueVideoPlay from "helpers/isUniqueVideoPlay";
import useActionFragment from "helpers/useActionFragment";
import ReelViewAvatar, { ReelViewAvatarFragment } from "molecules/ReelAvatar";
import React from "react";

import ReelVideoPlayer from "./ReelVideoPlayer";

export const ReelPlayerFragment = gql`
  fragment ReelPlayerFragment on ReelView {
    title
    eventsCount
    viewCount
    thumbnailUrl
    videoUrl
    sharingDisabled
    ...ReelViewAvatarFragment
  }
  ${ReelViewAvatarFragment}
`;

export default function ReelPlayer({ reelViewId, reelView }) {
  const reelViewIncreaseViewCount = useActionFragment("reelViewIncreaseViewCount", "reelView { id viewCount }");
  const reelViewIncreaseShareCount = useActionFragment("reelViewIncreaseShareCount");

  return (
    <>
      <ReelVideoPlayer
        avatar={<ReelViewAvatar reelView={reelView} />}
        title={reelView?.title}
        subtitle={<>{reelView?.eventsCount} highlights</>}
        poster={reelView?.thumbnailUrl}
        videoUrl={reelView?.videoUrl}
        shareUrl={`${ONSEN_URL}/reel/${reelViewId}`}
        sharingDisabled={reelView?.sharingDisabled === true}
        viewCount={reelView?.viewCount}
        onShared={({ share_destination }) => {
          gaEvent("reel_share", {
            share_destination,
          });
          reelViewIncreaseShareCount({ input: { reelViewId } });
        }}
        onVideoPlay={() => {
          const isUnique = isUniqueVideoPlay("reel_" + reelViewId);
          gaEvent("reel_play", {
            is_unique: isUnique,
          });
          reelViewIncreaseViewCount({ input: { reelViewId, isUnique } });
        }}
      />
    </>
  );
}
