import useFullscreenState from "helpers/useFullscreenState";
import { Fullscreen, FullscreenExit } from "mdi-material-ui";
import { SUPPORT_FULLSCREEN } from "providers/FullscreenStateProvider";
import React from "react";

import PlayerButton from "./PlayerButton";

export default function FullscreenButton({ video }) {
  const [fullscreen, fullscreenSet] = useFullscreenState();
  const requestFullScreen =
    video?.requestFullscreen ||
    (video?.webkitSupportsFullscreen ? video.webkitEnterFullscreen : null) ||
    video?.mozRequestFullScreen ||
    video?.msRequestFullscreen;
  const supportVideoFullscreen = video && requestFullScreen;

  if (!supportVideoFullscreen && !SUPPORT_FULLSCREEN) return null;

  return (
    <PlayerButton
      title="Fullscreen"
      onClick={() => {
        if (SUPPORT_FULLSCREEN) return fullscreenSet(!fullscreen);
        if (supportVideoFullscreen) return requestFullScreen.apply(video);
      }}
      icon={fullscreen ? <FullscreenExit /> : <Fullscreen />}
    />
  );
}
