import { Grow, Paper, Popper } from "@mui/material";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { ONSEN_ENV } from "helpers/environment";
import { SPACING_DENSE } from "helpers/spacings";
import React, { cloneElement, useRef, useState } from "react";

import KeyPressLabel from "./KeyPressLabel";

export default function WithTooltip({
  component: Component = "div",
  element = <Component />,
  tooltip,
  shortcutLabel,
  placement = "bottom",
  inverse = false,
  ...others
}) {
  const [hover, hoverSet] = useState(false);
  const ref = useRef();
  const touchedAtRef = useRef();

  if (ONSEN_ENV === "test") {
    return cloneElement(element, { title: tooltip, ...others });
  }

  return (
    <>
      {cloneElement(element, {
        ref,
        ...others,
        onTouchStart: (event) => {
          touchedAtRef.current = Date.now();
          element.props.onTouchStart?.(event);
        },
        onMouseEnter: (event) => {
          if (!touchedAtRef.current || Date.now() - touchedAtRef.current > 1000) {
            hoverSet(true);
          }
          element.props.onMouseEnter?.(event);
        },
        onMouseLeave: (event) => {
          hoverSet(false);
          element.props.onMouseLeave?.(event);
        },
      })}
      <Popper
        open={hover && !!tooltip}
        style={{ pointerEvents: "none", zIndex: 999999, opacity: 0.9 }}
        anchorEl={ref.current}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              style={{
                margin: SPACING_DENSE,
                padding: SPACING_DENSE,
                ...(inverse && {
                  backgroundColor: COLOR_BLACK,
                  color: COLOR_WHITE,
                }),
              }}
            >
              {tooltip}
              {shortcutLabel && (
                <span style={{ fontSize: "0.8em" }}>
                  <KeyPressLabel inverse={inverse}>{shortcutLabel}</KeyPressLabel>
                </span>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
