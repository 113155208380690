import { gql } from "@apollo/client";
import { SHORTCUT_KEY_CODE_VIDEO_NEXT_HIGHLIGHT, SHORTCUT_KEY_CODE_VIDEO_PREVIOUS_HIGHLIGHT } from "helpers/SHORTCUTS";
import useDOMEvent from "helpers/useDOMEvent";
import { videoPageContext } from "pages/VideoPage";
import { useContext } from "react";

export const VideoEventNavShortcutHandlerFragment = gql`
  fragment VideoEventNavShortcutHandlerFragment on Game {
    id
    events {
      id
      startAtSeconds
    }
  }
`;

export default function VideoEventNavShortcutHandler({ game }) {
  const { isVideoPageModalActive, activeEventId, currentTime, videoRef, filteredEventIds } =
    useContext(videoPageContext);

  const otherEvents = game?.events.filter((event) => filteredEventIds.includes(event.id) && event.id !== activeEventId);

  useDOMEvent("keydown", (evt) => {
    if (!isVideoPageModalActive) return;
    if (evt.metaKey || evt.ctrlKey || evt.altKey || evt.shiftKey) return;
    if (evt.code === SHORTCUT_KEY_CODE_VIDEO_PREVIOUS_HIGHLIGHT) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const event = otherEvents?.findLast((event) => event.startAtSeconds <= currentTime);
      if (event) videoRef.current.currentTime = event.startAtSeconds;
    }
    if (evt.code === SHORTCUT_KEY_CODE_VIDEO_NEXT_HIGHLIGHT) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const event = otherEvents?.find((event) => event.startAtSeconds > currentTime);
      if (event) videoRef.current.currentTime = event.startAtSeconds;
    }
  });
  return null;
}
