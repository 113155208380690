import { SplashScreen } from "@capacitor/splash-screen";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import apolloCache from "helpers/apolloCache";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import { GLORYLEAGUE_API_URL } from "helpers/environment";
import { initGA } from "helpers/gtag";
import { progressBar } from "helpers/useProgressBar";
import Cookie from "js-cookie";
import { defer } from "lodash-es";
import AuthTokenRefresher from "providers/AuthTokenRefresher";
import ErrorPageProvider from "providers/ErrorPageProvider";
import FullscreenStateProvider from "providers/FullscreenStateProvider";
import MessagesProvider from "providers/MessagesProvider";
import ModalContextProvider from "providers/ModalContextProvider";
import MuiProvider from "providers/MuiProvider";
import UISettingsProvider from "providers/UISettingsProvider";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "routes";
import FormPage from "shared/FormPage";
import NotFoundPage from "shared/NotFoundPage";
import { Page } from "shared/Page";

async function init() {
  // extract auth=token from url
  const url = new URL(window.location.href);
  const token = url.searchParams.get("auth");
  if (token) {
    window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, token);
    url.searchParams.delete("auth");
    window.history.replaceState({}, document.title, url);
  }

  // migrate from old EmberAuth token
  let emberAuthSession = window.cookieStore && Cookie.get("ember_simple_auth-session");
  if (emberAuthSession) {
    emberAuthSession = decodeURIComponent(emberAuthSession);
    try {
      emberAuthSession = JSON.parse(emberAuthSession);
    } catch (error) {
      defer(() => {
        throw new Error(`Error parsing emberAuthSession: ${error.message}`);
      });
      emberAuthSession = null;
    }
    const id = emberAuthSession?.authenticated?.id;
    const token = emberAuthSession?.authenticated?.token;
    if (id && token) {
      window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, `EMBER_LEGACY_TOKEN:${id}:${token}`);
      Cookie.remove("ember_simple_auth-session");
    }
  }
}

const ONSEN_API_VERSION = "4";

export default function App() {
  const [inited, initedSet] = useState(false);
  const [onsenApiVersion, onsenApiVersionSet] = useState(null);

  useEffect(() => {
    progressBar(async () => {
      await init();
      await initGA();
      await persistCache({
        cache: apolloCache,
        storage: new LocalStorageWrapper(window.localStorage),
      });
      initedSet(true);
      SplashScreen.hide();

      let response;
      try {
        response = await fetch(GLORYLEAGUE_API_URL + "/onsen_api_version");
      } catch (error) {
        defer(() => {
          throw error;
        });
      }
      if (response?.ok) {
        onsenApiVersionSet(await response.text());
      } else {
        // not available, possibly server offline
        onsenApiVersionSet("");
      }
    });
  }, []);

  const onsenApiVersionMismatch = onsenApiVersion !== null && onsenApiVersion !== ONSEN_API_VERSION;

  return (
    <UISettingsProvider>
      <MuiProvider>
        <MessagesProvider>
          <ModalContextProvider name="App">
            <FullscreenStateProvider>
              <ErrorPageProvider>
                {inited && (
                  <>
                    {onsenApiVersionMismatch ? (
                      <OnsenWaiting />
                    ) : (
                      <>
                        <AuthTokenRefresher />
                        <BrowserRouter>
                          <Routes>
                            {routes.map(([path, Component]) => (
                              <Route key={path} path={path} element={<Page Component={Component} />} />
                            ))}
                            <Route path="*" element={<Page Component={NotFoundPage} />} />
                          </Routes>
                        </BrowserRouter>
                      </>
                    )}
                  </>
                )}
              </ErrorPageProvider>
            </FullscreenStateProvider>
          </ModalContextProvider>
        </MessagesProvider>
      </MuiProvider>
    </UISettingsProvider>
  );
}

function OnsenWaiting() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div style={{ height: "100svh", display: "flex", justifyContent: "stretch", alignItems: "stretch" }}>
      <FormPage title="Coming back soon">
        <div>Glory League is currently undergoing maintenance and will be back soon.</div>
      </FormPage>
    </div>
  );
}
