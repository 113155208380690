import { gql } from "@apollo/client";
import { ButtonBase, Slider } from "@mui/material";
import Avatar from "atoms/Avatar";
import Stack from "atoms/Stack";
import VideoPlayer from "atoms/VideoPlayer";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_BLACK, getStatColor } from "helpers/colors";
import { EVENT_FINISH_OFFSET_SECONDS_MAX, EVENT_START_OFFSET_SECONDS_MAX } from "helpers/constants";
import EVENT_TEMPLATES, { EVENT_DEFAULT_PARAMS, getEventTemplate } from "helpers/EVENT_TEMPLATES";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import formatTimeString from "helpers/formatTime";
import { Pause, Play, Refresh } from "mdi-material-ui";
import GamePlayerAvatar, { GamePlayerAvatarFragment } from "molecules/GamePlayerAvatar";
import PlayerButton from "pages/video_player/PlayerButton";
import React, { useEffect, useRef, useState } from "react";
import { useComponentSize } from "react-use-size";

export const HighlightEditorFragment = gql`
  fragment HighlightEditorFragment on Game {
    id
    league {
      sport
    }
    videoHlsUrl
    durationSeconds
    participations {
      id
      team {
        id
        name
      }
    }
    gamePlayers {
      id
      playerNumber
      ...GamePlayerAvatarFragment
      team {
        id
      }
      person {
        id
        shortNameWithAnonymisation
      }
    }
  }
  ${GamePlayerAvatarFragment}
`;

export const HighlightEditorEventFragment = gql`
  fragment HighlightEditorEventFragment on Event {
    startAtSeconds
    occurredAtSeconds
    finishAtSeconds
    team {
      id
    }
    person {
      id
    }
    eventView {
      id
      wrapper
      points
      made
      reboundType
    }
  }
`;

export default function HighlightEditor({ open = false, game, event, eventSet }) {
  const componentSize = useComponentSize();

  const horizontal = componentSize.width > componentSize.height;
  const currentEventTemplate = getEventTemplate(game?.league.sport, event);
  const [sliderRange, sliderRangeSet] = useState(null);

  const resetSliderRange = () => {
    sliderRangeSet([
      //
      Math.max(0, (event.occurredAtSeconds || 0) - EVENT_START_OFFSET_SECONDS_MAX),
      Math.min((event.occurredAtSeconds || 0) + EVENT_FINISH_OFFSET_SECONDS_MAX, game.durationSeconds),
    ]);
  };

  useEffect(() => {
    if (open && game) {
      resetSliderRange();
    }
  }, [open, !!game]);
  const videoRef = useRef();
  const [playing, playingSet] = useState(false);
  const [currentTime, currentTimeSet] = useState(0);

  return (
    <Stack componentRef={componentSize.ref} reverse horizontal={horizontal} style={{ flex: "1 1 auto" }}>
      {!event?.eventView.wrapper?.endsWith("Reaction") && (
        <Stack style={{ flex: "1 0 0" }}>
          <Stack dense horizontal alignItemsCenter>
            {EVENT_TEMPLATES.filter((t) => t.sport === game?.league.sport).map((eventTemplate) => (
              <Avatar
                key={eventTemplate.title}
                size={32}
                inverse
                color={getStatColor(eventTemplate.title)}
                active={currentEventTemplate === eventTemplate}
                imgSrc={eventTemplate.imgSrc}
                text={eventTemplate.shortName}
                element={
                  <WithTooltip
                    tooltip={eventTemplate.title}
                    inverse
                    element={
                      <ButtonBase
                        focusRipple
                        onClick={() =>
                          eventSet({
                            ...event,
                            eventView: {
                              ...event.eventView,
                              ...EVENT_DEFAULT_PARAMS,
                              ...eventTemplate.params,
                            },
                          })
                        }
                      />
                    }
                  />
                }
              />
            ))}
          </Stack>
          <Stack horizontal dense alignItemsCenter>
            {game?.participations.map((participation) => (
              <ButtonBase
                focusRipple
                key={participation.id}
                onClick={() =>
                  eventSet({
                    //
                    ...event,
                    person: null,
                    team: { id: participation.team.id, __typename: "Team" },
                  })
                }
                style={{
                  fontSize: 22,
                  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                  textTransform: "uppercase",
                  opacity: event.team?.id === participation.team.id ? 1 : 0.5,
                }}
              >
                {participation.team.name}
              </ButtonBase>
            ))}
          </Stack>

          <div
            style={{
              position: "relative",
              height: 200,
              flex: "1 1 0",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: "auto",
              }}
            >
              <Stack dense>
                {game?.gamePlayers
                  .filter((gp) => gp.team.id === event.team?.id)
                  .map((gamePlayer) => (
                    <Stack
                      key={gamePlayer.id}
                      dense
                      horizontal
                      alignItemsCenter
                      component={ButtonBase}
                      focusRipple
                      onClick={() => eventSet({ ...event, person: { id: gamePlayer.person.id, __typename: "Person" } })}
                    >
                      <GamePlayerAvatar
                        active={event.person?.id === gamePlayer.person.id}
                        gamePlayer={gamePlayer}
                        size={32}
                        inverse
                      />
                      <div
                        style={{
                          fontWeight: "bold",
                          opacity: event.person?.id === gamePlayer.person.id ? 1 : 0.5,
                        }}
                      >
                        {gamePlayer.person.shortNameWithAnonymisation} #{gamePlayer.playerNumber}
                      </div>
                    </Stack>
                  ))}
              </Stack>
            </div>
          </div>
        </Stack>
      )}
      <Stack
        style={{
          flex: "1 0 0",
        }}
      >
        <div style={{ position: "relative", flex: "1 1 0" }}>
          {open && game?.videoHlsUrl && event.startAtSeconds !== null && event.finishAtSeconds !== null && (
            <>
              <VideoPlayer
                playsInline
                autoPlay
                loop
                videoRef={videoRef}
                src={game.videoHlsUrl}
                style={{
                  backgroundColor: COLOR_BLACK,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                onTimeUpdate={() => {
                  if (videoRef.current.paused) return;
                  // clamp the currentTime to the slider range
                  let currentTime = videoRef.current.currentTime;
                  if (currentTime < event.startAtSeconds) {
                    videoRef.current.currentTime = event.startAtSeconds;
                  } else if (currentTime > event.finishAtSeconds) {
                    videoRef.current.currentTime = event.startAtSeconds;
                  }
                  currentTimeSet(videoRef.current.currentTime);
                }}
                onPlay={() => {
                  playingSet(true);
                }}
                onPause={() => {
                  playingSet(false);
                }}
              />
            </>
          )}
        </div>
        <Stack dense horizontal alignItemsCenter>
          <PlayerButton
            title={playing ? "Pause" : "Play"}
            icon={playing ? <Pause /> : <Play />}
            onClick={() => {
              if (playing) videoRef.current.pause();
              else videoRef.current.play();
            }}
          />
          <PlayerButton
            title="Replay"
            icon={<Refresh />}
            onClick={() => {
              videoRef.current.currentTime = event.startAtSeconds || 0;
              videoRef.current.play();
            }}
          />
          <div>{formatTimeString(currentTime)}</div>
          <div style={{ flex: "1 1 0" }}>
            {sliderRange && (
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value, valueIndex) =>
                  `${
                    [
                      //
                      "Video Start",
                      "Highlight Time",
                      "Video End",
                    ][valueIndex]
                  }: ${formatTimeString(value)}`
                }
                style={{ margin: "0 8px" }}
                color="secondary"
                value={[event.startAtSeconds, event.occurredAtSeconds, event.finishAtSeconds]}
                step={0.1}
                min={sliderRange[0]}
                max={sliderRange[1]}
                onChange={(_, value, activeThumb) => {
                  eventSet({
                    ...event,
                    startAtSeconds: value[0],
                    occurredAtSeconds: value[1],
                    finishAtSeconds: value[2],
                  });
                  // preview frame while dragging slider
                  videoRef.current.pause();
                  const jumpToTime = [event.startAtSeconds, event.occurredAtSeconds, event.finishAtSeconds][
                    activeThumb
                  ];
                  videoRef.current.currentTime = jumpToTime;
                }}
                onChangeCommitted={() => {
                  resetSliderRange();
                  videoRef.current.play();
                }}
              />
            )}
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}
