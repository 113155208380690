import { gql } from "@apollo/client";
import MuteText from "atoms/MuteText";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useConfirmDialog from "helpers/useConfirmDialog";
import useGotoUrl from "helpers/useGotoUrl";
import useShowMessage from "helpers/useShowMessage";
import { Pencil, Play, Share, TrashCan } from "mdi-material-ui";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useState } from "react";

import EditPlaylistDialog from "./EditPlaylistDialog";
import SharePlaylistDialog from "./SharePlaylistDialog";

export const PlaylistInfoFragment = gql`
  fragment PlaylistInfoFragment on Playlist {
    id
    description
    name
    canUpdate
    isPublic
    clips {
      id
    }
  }
`;

export default function PlaylistInfo({ playlist }) {
  const gotoUrl = useGotoUrl();
  const [editing, editingSet] = useState(false);
  const [sharing, sharingSet] = useState(false);
  const showMessage = useShowMessage();
  const [confirmDialog, confirmDialogOpen] = useConfirmDialog();
  const playlistDelete = useActionFragment("playlistDelete", "currentUser { id playlists { id } }");

  return (
    <>
      {confirmDialog}
      <EditPlaylistDialog open={editing} playlistId={playlist?.id} onClose={() => editingSet(false)} />
      <SharePlaylistDialog open={sharing} playlistId={playlist?.id} onClose={() => sharingSet(false)} />
      {playlist?.description && (
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {playlist?.description}
        </div>
      )}
      {playlist && (
        <MuteText>
          {playlist.isPublic ? (
            <>This is a public playlist. Anyone with the link can view this playlist.</>
          ) : (
            <>This is a private playlist. Only you can view this playlist.</>
          )}
        </MuteText>
      )}
      <Toolbar>
        <ToolbarButton
          href={`/playlists/${playlist?.id}/${playlist?.clips[0]?.id}`}
          icon={<Play />}
          disabled={!playlist?.clips.length > 0}
          label="Play"
          primary
        />
        {playlist?.canUpdate && <ToolbarButton onClick={() => editingSet(true)} icon={<Pencil />} label="Edit" />}
        {playlist?.canUpdate && (
          <ToolbarButton
            icon={<Share />}
            label="Share"
            disabled={!playlist}
            onClick={() => {
              gaEvent("playlist_share", {
                method: "share",
                action: "click",
                location: "overview",
              });
              sharingSet(true);
            }}
          />
        )}
        {playlist?.canUpdate && (
          <ToolbarButton
            icon={<TrashCan />}
            label="Delete"
            onClick={async () => {
              await confirmDialogOpen({
                title: "Delete Playlist",
                content: (
                  <>
                    <div>Are you sure to delete this playlist?</div>
                    <MuteText>Note: you won&apos;t be able to undo this action.</MuteText>
                  </>
                ),
              });
              await playlistDelete({ input: { playlistId: playlist.id } });
              await showMessage(`Playlist ${playlist.name} deleted`);
              gotoUrl("/playlists");
            }}
          />
        )}
      </Toolbar>
      {playlist?.clips.length === 0 && (
        <div>
          This playlist looks empty. Watch your <a href="/games">latest games</a> to start adding highlights.
        </div>
      )}
    </>
  );
}
