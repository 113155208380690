import ClipSvg from "assets/reactions/clip.svg";
import FireSvg from "assets/reactions/fire.svg";
import GoodJobSvg from "assets/reactions/good-job.svg";
import LikeSvg from "assets/reactions/like.svg";

const REACTION_TYPES = [
  {
    name: "clip",
    imgSrc: ClipSvg,
    title: "Clip",
  },
  {
    name: "like",
    imgSrc: LikeSvg,
    title: "Like",
  },
  {
    name: "good_job",
    imgSrc: GoodJobSvg,
    title: "Good Job",
  },
  {
    name: "fire",
    imgSrc: FireSvg,
    title: "Fire",
  },
];

export default REACTION_TYPES;
