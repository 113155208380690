import Droppable from "atoms/Droppable";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import React, { cloneElement, useState } from "react";

export default function ClipDroppable({ clipId, component, vertical = false, ...others }) {
  const [droppingStart, droppingStartSet] = useState(false);
  const clipMoveToClip = useActionFragment(
    "clipMoveToClip",
    `
      clip { id playlist { id } }
      playlist { id clips { id } }
      oldPlaylist { id clips { id } }
    `,
  );

  return (
    <Droppable
      dataType="clip"
      onDroppingZoneChange={(zone) => droppingStartSet(vertical ? zone.top : zone.left)}
      style={{
        transition: "padding 0.3s, margin 0.3s",
      }}
      droppingStyle={
        vertical
          ? droppingStart
            ? {
                paddingTop: "3em",
                marginBottom: "-3em",
              }
            : {
                paddingBottom: "3em",
                marginTop: "-3em",
              }
          : droppingStart
            ? {
                paddingLeft: "3em",
                marginRight: "-3em",
              }
            : {
                marginLeft: "-3em",
                paddingRight: "3em",
              }
      }
      onDropData={(clip) => {
        if (clip.id === clipId) return;
        clipMoveToClip({
          input: { clipId: clip.id, toClipId: clipId, after: !droppingStart },
        });
        gaEvent("playlist_move_clip", {
          method: "drag",
        });
      }}
    >
      {cloneElement(component, {
        ...others,
        style: {
          ...component.props.style,
          ...others.style,
        },
      })}
    </Droppable>
  );
}
