import { Dialog as MUIDialog, Grow, IconButton } from "@mui/material";
import Stack from "atoms/Stack";
import { COLOR_PRIMARY_DARKER, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING_DENSE } from "helpers/spacings";
import { CloseCircle } from "mdi-material-ui";
import ModalContextProvider from "providers/ModalContextProvider";
import React from "react";
import { useWindowSize } from "react-use-size";

import Toolbar, { ToolbarButton } from "./Toolbar";

export default function Dialog({
  name,
  title,
  inverse = false,
  fullWidth = false,
  mediumWidth = false,
  fullHeight = false,
  noPadding = false,
  open,
  onClose,
  children,
  actions,
  ...others
}) {
  let maxWidth = "sm";
  if (mediumWidth) maxWidth = "md";
  if (fullWidth) maxWidth = false;
  const windowSize = useWindowSize();
  const smallScreen = windowSize.width < 500 || windowSize.height < 500;

  return (
    <ModalContextProvider open={open} name={`Dialog-${name}`}>
      <MUIDialog
        fullScreen={smallScreen}
        maxWidth={maxWidth}
        fullWidth
        {...others}
        open={open}
        onClose={onClose}
        PaperProps={{
          ...others.PaperProps,
          style: {
            ...(inverse && {
              backgroundColor: COLOR_PRIMARY_DARKER,
              color: COLOR_WHITE,
            }),
            display: "flex",
            flexFlow: "column nowrap",
            ...(fullHeight && {
              height: "100svh",
            }),
            paddingTop: "env(safe-area-inset-top)",
            paddingBottom: "env(safe-area-inset-top)",
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
            ...others.PaperProps?.style,
          },
        }}
        TransitionComponent={Grow}
        TransitionProps={{ easing: "ease-in-out", timeout: 300 }}
      >
        <Stack
          padding={!noPadding}
          style={{
            flex: "1 1 auto",
            position: "relative",
          }}
        >
          {title && (
            <h1
              style={{
                textTransform: "uppercase",
                fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                fontSize: 24,
                lineHeight: 1.5,
                margin: 0,
              }}
            >
              {title}
            </h1>
          )}
          {children}
          {actions && <Toolbar>{actions}</Toolbar>}
          <IconButton
            size="small"
            color="inherit"
            onClick={onClose}
            style={{ position: "absolute", zIndex: 1, top: SPACING_DENSE, right: SPACING_DENSE }}
          >
            <CloseCircle />
          </IconButton>
        </Stack>
      </MUIDialog>
    </ModalContextProvider>
  );
}

export function DialogActionButton({ ...others }) {
  return <ToolbarButton {...others} />;
}
