import { gql } from "@apollo/client";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import { ClipboardOutline, Share, ShareOff } from "mdi-material-ui";
import Dialog, { DialogActionButton } from "molecules/Dialog";
import React from "react";

export default function SharePlaylistDialog({ open, playlistId, onClose }) {
  const [data] = useData(
    gql`
      query SharePlaylistDialog($playlistId: ID!) {
        playlist(id: $playlistId) {
          id
          name
          isPublic
        }
      }
    `,
    { playlistId },
    { skip: !open || !playlistId },
  );
  const playlistToggleShare = useActionFragment("playlistToggleShare", `playlist { id isPublic playlistType }`);
  const shareUrl = window.location.origin + "/playlists/" + playlistId;
  const showMessage = useShowMessage();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Share Playlist"
      actions={
        <>
          {data?.playlist.isPublic && (
            <DialogActionButton
              label={<>Unshare</>}
              icon={<ShareOff />}
              onClick={async () => {
                await playlistToggleShare({
                  input: {
                    playlistId,
                    shared: false,
                  },
                });
                gaEvent("playlist_share", {
                  method: "unshare",
                  action: "confirm",
                });
              }}
            />
          )}
          {data?.playlist.isPublic === false && (
            <DialogActionButton
              label={<>Make it public</>}
              icon={<Share />}
              primary
              onClick={async () => {
                await playlistToggleShare({
                  input: {
                    playlistId,
                    shared: true,
                  },
                });
                gaEvent("playlist_share", {
                  method: "share",
                  action: "confirm",
                });
              }}
            />
          )}
          <DialogActionButton label="Close" onClick={onClose} />
        </>
      }
    >
      {data?.playlist.isPublic === false && (
        <>
          <div>This playlist is not public.</div>
          <div>Do you want to make it public? This will allow anyone with the link to view this playlist.</div>
        </>
      )}
      {data?.playlist.isPublic && (
        <>
          <div>This playlist has already been shared and visible to all who know the Playlist URL</div>
          <TextField
            label="Playlist URL"
            helperText="Copy this URL to share this playlist with others."
            value={shareUrl}
            readOnly
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      await navigator.clipboard.writeText(shareUrl);
                      showMessage("Copied to clipboard");
                    }}
                  >
                    <ClipboardOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    </Dialog>
  );
}
