const KEY_PREFIX = "gloryleague_video_played_";

export default function isUniqueVideoPlay(slug) {
  const key = `${KEY_PREFIX}${slug}`;
  if (localStorage.getItem(key)) {
    return false;
  }
  localStorage.setItem(key, "1");
  return true;
}
