import { darken, lighten, Skeleton } from "@mui/material";
import { COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import React from "react";

export default function Splash({
  logo,
  logoLoading = false,
  loading = false,
  title,
  descriptionHeader,
  descriptionValue,
  color,
}) {
  const colorDarken = darken(color, 0.3);
  const colorSkeleton = lighten(color, 0.5);

  return (
    <>
      <div
        style={{
          backgroundColor: color,
          color: COLOR_WHITE,
          background: `linear-gradient(0deg, ${color}, ${colorDarken})`,
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          padding: 40,
          paddingBottom: 20,
          gap: 40,
        }}
      >
        <div style={{ flex: "0 1 auto" }}>
          {logo ||
            (logoLoading && (
              <Skeleton variant="circular" style={{ width: 140, height: 140, backgroundColor: colorSkeleton }} />
            ))}
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: `min(60px, 10vw)`,
              textTransform: "uppercase",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontWeight: 200,
            }}
          >
            {title || (loading && <Skeleton style={{ width: "5em", backgroundColor: colorSkeleton }} />)}
          </h1>
          <div
            style={{
              textTransform: "uppercase",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              opacity: 0.5,
            }}
          >
            {descriptionHeader || (loading && <Skeleton style={{ width: "2em", backgroundColor: colorSkeleton }} />)}
          </div>
          <h2
            style={{
              margin: 0,
              textTransform: "uppercase",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontWeight: 200,
              fontSize: 24,
            }}
          >
            {descriptionValue || (loading && <Skeleton style={{ width: "5em", backgroundColor: colorSkeleton }} />)}
          </h2>
        </div>
      </div>
    </>
  );
}
