import { gql } from "@apollo/client";
import { TextField } from "@mui/material";
import apolloClient from "helpers/apolloClient";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import useAction from "helpers/useAction";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useGotoUrl from "helpers/useGotoUrl";
import useShowMessage from "helpers/useShowMessage";
import React, { useLayoutEffect, useMemo, useState } from "react";
import FormPage, { FormPageSubmitButton } from "shared/FormPage";
import PageTitle from "shared/PageTitle";

export default function ConfigurePasswordPage() {
  const onboardToken = useMemo(() => new URLSearchParams(location.search).get("onboardToken"), []);

  useEnforceAuth({ enforceUnauthenticated: true });

  const gotoUrl = useGotoUrl();
  const [data] = useData(
    gql`
      query ConfigurePasswordPage($onboardToken: String!) {
        onboardToken(onboardToken: $onboardToken) {
          userEmail
        }
      }
    `,
    { onboardToken },
    { skip: !onboardToken },
  );

  const configurePassword = useAction(gql`
    mutation ConfigurePasswordPage($input: ConfigurePasswordInput!) {
      configurePassword(input: $input) {
        authToken
      }
    }
  `);

  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  const [passwordConfirm, passwordConfirmSet] = useState("");
  const showMessage = useShowMessage();

  const passwordErrorMessage = password && password.length < 6 && <>Password must be at least 6 characters long.</>;
  const passwordConfirmErrorMessage = passwordConfirm && passwordConfirm !== password && <>Passwords do not match.</>;

  useLayoutEffect(() => {
    emailSet(data?.onboardToken?.userEmail || "");
  }, [data]);

  if (!onboardToken || (data && !data.onboardToken)) {
    gotoUrl("/login", { replace: true });
  }

  return (
    <>
      <PageTitle title="Set Password" />
      <FormPage
        title={
          <>
            Welcome to <b>Glory League</b>
          </>
        }
        onSubmit={async () => {
          if (!password || passwordErrorMessage || passwordConfirmErrorMessage) return;
          const result = await configurePassword({
            input: {
              onboardToken,
              email,
              password,
            },
          });
          window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, result.configurePassword.authToken);
          await apolloClient.query({
            fetchPolicy: "network-only",
            query: gql`
              query ConfigurePasswordPage {
                currentUser {
                  id
                }
              }
            `,
          });
          showMessage("Password set successfully!", "success");
        }}
      >
        <div>Please set your password to login and watch your game video.</div>
        <TextField
          label="Email"
          value={email}
          onChange={(event) => emailSet(event.target.value)}
          disabled={!data}
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(event) => passwordSet(event.target.value)}
          error={!!passwordErrorMessage}
          helperText={passwordErrorMessage}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          value={passwordConfirm}
          onChange={(event) => passwordConfirmSet(event.target.value)}
          error={!!passwordConfirmErrorMessage}
          helperText={passwordConfirmErrorMessage}
        />
        <FormPageSubmitButton label="Save and Continue" disabled={!data} />
        <div>
          If you have already set a password. Please <a href="/login">login</a> instead.
        </div>
      </FormPage>
    </>
  );
}
