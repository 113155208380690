import { gql } from "@apollo/client";
import { alpha, ButtonBase } from "@mui/material";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_GREY_BORDER } from "helpers/colors";
import { SPACING_DENSE, SPACING_DENSE_HALF } from "helpers/spacings";
import useData from "helpers/useData";
import usePseudoStyle from "helpers/usePseudoStyle";
import GamePlayerAvatar, { GamePlayerAvatarFragment } from "molecules/GamePlayerAvatar";
import React from "react";

export default function GamePlayerSelector({ gamePlayerIdSet, gameId, gamePlayerId }) {
  const [data] = useData(
    gql`
      query GamePlayerSelector($gameId: ID!) {
        game(id: $gameId) {
          id
          home {
            id
            name
          }
          away {
            id
            name
          }
          gamePlayers {
            id
            ...GamePlayerAvatarFragment
            person {
              id
              fullNameWithAnonymisation
            }
            team {
              id
            }
          }
        }
      }
      ${GamePlayerAvatarFragment}
    `,
    { gameId },
  );

  const game = data?.game;
  const teams = game && [game.home, game.away];

  return (
    <>
      <Stack horizontal>
        {teams?.map((team) => (
          <div
            key={team.id}
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "stretch",
              width: 200,
            }}
          >
            <div
              style={{
                padding: SPACING_DENSE,
                borderBottom: `1px solid ${COLOR_GREY_BORDER}`,
              }}
            >
              {team.name}
            </div>
            {game?.gamePlayers
              .filter((gp) => gp.team.id === team.id)
              .map((gamePlayer) => (
                <GamePlayerSelectorItem
                  key={gamePlayer.id}
                  selected={gamePlayer.id === gamePlayerId}
                  onClick={() => {
                    gamePlayerIdSet(gamePlayer.id);
                  }}
                  gamePlayer={gamePlayer}
                />
              ))}
          </div>
        ))}
      </Stack>
    </>
  );
}

function GamePlayerSelectorItem({ gamePlayer, selected = false, onClick }) {
  const { className } = usePseudoStyle({
    ":hover": `font-weight: bold !important; background-color: ${alpha(COLOR_BLACK, 0.05)} !important;`,
  });

  return (
    <ButtonBase
      className={className}
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: SPACING_DENSE,
        paddingTop: SPACING_DENSE_HALF,
        paddingBottom: SPACING_DENSE_HALF,
        paddingLeft: SPACING_DENSE,
        paddingRight: SPACING_DENSE,
        ...(selected && {
          fontWeight: "bold",
          backgroundColor: alpha(COLOR_BLACK, 0.05),
        }),
      }}
    >
      <GamePlayerAvatar gamePlayer={gamePlayer} />
      <div>{gamePlayer.person.fullNameWithAnonymisation}</div>
    </ButtonBase>
  );
}
