import { gql } from "@apollo/client";
import Avatar from "atoms/Avatar";
import { getStatColor } from "helpers/colors";
import REACTION_TYPES from "helpers/REACTION_TYPES";
import React from "react";

export const EventMarkerFragment = gql`
  fragment EventMarkerFragment on EventView {
    name
    singleCharacter
    reactionType
    made
  }
`;

export default function EventMarker({ eventView, size = 16, active = false, inverse = false, ...others }) {
  let eventColor = getStatColor(eventView?.name);
  let eventChar = eventView?.singleCharacter;
  let imgSrc = null;

  if (eventView?.name === "Reaction") {
    imgSrc = REACTION_TYPES.find((r) => r.name === eventView?.reactionType)?.imgSrc || REACTION_TYPES[0]?.imgSrc;
  }

  return (
    <Avatar
      inverse={inverse}
      active={active}
      size={size}
      text={eventChar}
      color={eventColor}
      imgSrc={imgSrc}
      {...others}
    />
  );
}
