import { COLOR_PRIMARY_DARKER, COLOR_WHITE } from "helpers/colors";
import formatTimeString from "helpers/formatTime";
import useDOMEvent from "helpers/useDOMEvent";
import React, { useRef, useState } from "react";

let _playingWhenMouseDown = false;

export default function Timeline({ currentTime, duration, videoRef, children }) {
  const [draggingTimeline, draggingTimelineSet] = useState(false);
  const handleDraggingTimeline = (event) => {
    const rect = timelineRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const ratio = x / rect.width;
    videoRef.current.currentTime = ratio * duration;
  };
  const timelineRef = useRef();

  useDOMEvent("mousemove", (event) => {
    if (!draggingTimeline) return;
    handleDraggingTimeline(event);
  });

  useDOMEvent("touchmove", (event) => {
    if (!draggingTimeline) return;
    handleDraggingTimeline(event.touches[0]);
  });

  useDOMEvent("mouseup", () => {
    draggingTimelineSet(false);
    if (_playingWhenMouseDown) {
      videoRef.current.play();
    }
    _playingWhenMouseDown = false;
  });

  useDOMEvent("touchend", () => {
    draggingTimelineSet(false);
    if (_playingWhenMouseDown) {
      videoRef.current.play();
    }
    _playingWhenMouseDown = false;
  });

  return (
    <>
      <div>{formatTimeString(currentTime)}</div>
      <div
        data-video-joyride="video-timeline"
        style={{
          flex: "1 1 auto",
          position: "relative",
          backgroundColor: COLOR_PRIMARY_DARKER,
          height: 16,
          borderRadius: 8,
          cursor: "pointer",
        }}
        ref={timelineRef}
        onMouseDown={(event) => {
          draggingTimelineSet(true);
          _playingWhenMouseDown = !videoRef.current.paused;
          videoRef.current.pause();
          handleDraggingTimeline(event);
        }}
        onTouchStart={(event) => {
          if (event.touches.length !== 1) return;
          draggingTimelineSet(true);
          _playingWhenMouseDown = !videoRef.current.paused;
          videoRef.current.pause();
          handleDraggingTimeline(event.touches[0]);
        }}
      >
        {children}
        {/* tick */}
        {duration && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: `${(currentTime / duration) * 100}%`,
              transform: "translate(-50%, -50%)",
              height: 24,
              width: 3,
              backgroundColor: COLOR_WHITE,
              borderRadius: 8,
              zIndex: 1,
            }}
          />
        )}
      </div>
    </>
  );
}
