import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow } from "@mui/material";
import { ButtonBase } from "@mui/material";
import bannersplash from "assets/bannersplash.png";
import signaturePng from "assets/signature.png";
import whiteJersey from "assets/white-jersey.png";
import { COLOR_PRIMARY_DARKER, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import React, { useState } from "react";
import { useComponentSize } from "react-use-size";

export default function IntroBanner({ title, description, learnMoreDialogContent }) {
  const [dialogOpen, dialogOpenSet] = useState(false);
  const size = useComponentSize();

  return (
    <div
      ref={size.ref}
      style={{
        padding: 20,
        backgroundColor: COLOR_PRIMARY_DARKER,
        color: COLOR_WHITE,
        backgroundImage: `url(${bannersplash})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: FONT_FAMILY_ROBOTO,
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.5,
        display: "flex",
        flexFlow: "column nowrap",
        gap: 10,
      }}
    >
      <div
        style={{
          fontSize: 72,
          fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
          fontWeight: 200,
          textTransform: "uppercase",
        }}
      >
        {title}
      </div>
      <div>{description}</div>
      <div>
        <ButtonBase style={{ color: COLOR_SECONDARY }} onClick={() => dialogOpenSet(true)}>
          See how it works
        </ButtonBase>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => dialogOpenSet(false)}
        TransitionComponent={Grow}
        TransitionProps={{ easing: "ease-in-out", timeout: 300 }}
        maxWidth="md"
      >
        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            <DialogTitle style={{ fontFamily: FONT_FAMILY_LEAGUE_GOTHIC, textTransform: "uppercase", fontSize: 26 }}>
              Plays of the Week
            </DialogTitle>
            <DialogContent
              style={{
                fontSize: 16,
                lineHeight: "1.5",
                fontFamily: FONT_FAMILY_ROBOTO,
              }}
            >
              {learnMoreDialogContent}
              <p>
                <img src={signaturePng} alt="Glory League" />
              </p>
              <p>Glory League Team</p>
            </DialogContent>
          </div>
          {size.width > 500 && <img src={whiteJersey} alt="White Jersey" />}
        </div>
        <DialogActions>
          <Button size="large" variant="contained" color="secondary" onClick={() => dialogOpenSet(false)}>
            Get Started
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
