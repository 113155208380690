import { gql } from "@apollo/client";
import Avatar from "atoms/Avatar";
import { PageContentStack } from "atoms/Stack";
import { ONSEN_URL } from "helpers/environment";
import { gaEvent } from "helpers/gtag";
import { Trophy } from "mdi-material-ui";
import React from "react";

import ReelVideoPlayer from "./ReelVideoPlayer";

export const PotwVideoPlayerFragment = gql`
  fragment PotwVideoPlayerFragment on PotwCompetition {
    name
    club {
      id
      name
    }
    videoUrl
  }
`;

export default function PotwVideoPlayer({ potwCompetition, potwCompetitionId }) {
  return (
    <>
      <PageContentStack style={{ flex: "1 1 auto" }} inverse>
        <ReelVideoPlayer
          avatar={<Avatar icon={<Trophy />} inverse />}
          title={potwCompetition?.name}
          subtitle={potwCompetition?.club.name}
          poster={potwCompetition?.thumbnailUrl}
          videoUrl={potwCompetition?.videoUrl}
          shareUrl={`${ONSEN_URL}/potw/competitions/${potwCompetitionId}/video`}
          sharingDisabled={potwCompetition?.shareable === false}
          onShared={({ share_destination }) => {
            gaEvent("potw_video_share", {
              share_destination,
            });
          }}
          onVideoPlay={() => {
            gaEvent("potw_video_play");
          }}
        />
      </PageContentStack>
    </>
  );
}
