import { gql } from "@apollo/client";
import useData from "helpers/useData";
import React, { useLayoutEffect } from "react";

export default function PageTitle({ title }) {
  const [data] = useData(gql`
    query PageTitle {
      currentUser {
        id
        publicLeagueTitle
      }
    }
  `);

  const platformTitle = data?.currentUser?.publicLeagueTitle || "Glory League";
  title = title ? `${title} | ${platformTitle}` : platformTitle;

  useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return null;
}

export function PotwPageTitle({ title }) {
  return <PageTitle title={title ? `${title} | Plays of the Week` : "Plays of the Week"} />;
}

export function PersonPageTitle({ title }) {
  return <PageTitle title={title ? `${title} | Profile` : "Profile"} />;
}

export function TeamPageTitle({ title }) {
  return <PageTitle title={title ? `${title} | Team` : "Team"} />;
}
