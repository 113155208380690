import { gql } from "@apollo/client";
import { ButtonBase, CircularProgress } from "@mui/material";
import { SHORTCUT_KEY_LABEL_VIDEO_PLAY_PAUSE } from "helpers/SHORTCUTS";
import { Pause, Play } from "mdi-material-ui";
import EventMarker, { EventMarkerFragment } from "molecules/EventMarker";
import FullscreenButton from "pages/video_player/FullscreenButton";
import PlayerButton from "pages/video_player/PlayerButton";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

import Timeline from "../video_player/Timeline";
import VolumeButton from "../video_player/VolumeButton";

export const VideoControlsFragment = gql`
  fragment VideoControlsFragment on Game {
    id
    events {
      id
      startAtSeconds
      occurredAtSeconds
      eventView {
        id
        ...EventMarkerFragment
      }
    }
  }
  ${EventMarkerFragment}
`;

export default function VideoControls({ game }) {
  const {
    playing,
    duration,
    buffering,
    currentTime,
    currentTimeSet,
    videoRef,
    audioEnabled,
    fullscreen,
    fullscreenSet,
    activeEventId,
    filteredEventIds,
  } = useContext(videoPageContext);

  return (
    <>
      <PlayerButton
        shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PLAY_PAUSE}
        title={playing ? "Pause" : "Play"}
        onClick={async () => {
          if (playing) {
            videoRef.current.pause();
          } else {
            await videoRef.current.play();
          }
        }}
        icon={playing ? <Pause /> : <Play />}
        decorators={
          playing &&
          buffering && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          )
        }
      />
      <VolumeButton enabled={audioEnabled} />
      <Timeline currentTime={currentTime} duration={duration} videoRef={videoRef} currentTimeSet={currentTimeSet}>
        {game?.events
          .filter((e) => filteredEventIds.includes(e.id))
          .map((event) => (
            <EventMarker
              component={ButtonBase}
              focusRipple
              inverse
              key={event.id}
              eventView={event.eventView}
              size={activeEventId === event.id ? 24 : 18}
              active={activeEventId === event.id}
              style={{
                position: "absolute",
                zIndex: activeEventId === event.id ? 2 : 1,
                top: "50%",
                left: `${(event.occurredAtSeconds / duration) * 100}%`,
                transform: "translate(-50%, -50%)",
              }}
              onClick={() => {
                videoRef.current.currentTime = event.startAtSeconds;
              }}
            />
          ))}
      </Timeline>
      <FullscreenButton video={videoRef.current} fullscreen={fullscreen} fullscreenSet={fullscreenSet} />
    </>
  );
}
