import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import Grid from "atoms/Grid";
import { PageContentStack } from "atoms/Stack";
import { gaSetSharedEventParams } from "helpers/gtag";
import { SPACING_DENSE } from "helpers/spacings";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import { Plus } from "mdi-material-ui";
import Message from "molecules/Message";
import Section from "molecules/Section";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import PageTitle from "shared/PageTitle";

import BoxScore from "./games/BoxScore";
import EventCard, { EventCardFragment } from "./games/EventCard";
import GameOverviewPageHeader from "./games/GameOverviewPageHeader";
import PointChart from "./games/PointChart";
import ReelCard, { ReelCardFragment } from "./games/ReelCard";
import ShotChart from "./games/ShotChart";

export default function GameOverviewPage({ gameId }) {
  useEnforceAuth({ enforceAuthenticated: true });
  let [gamePlayerId, gamePlayerIdSet] = useRouteState("gamePlayerId");
  const [tab, tabSet] = useRouteState("tab");
  const [permissionData] = useData(
    gql`
      query GameOverviewPage_permission($gameId: ID!) {
        game(id: $gameId) {
          id
          canOnsenAccessVideo
        }
      }
    `,
    { gameId },
  );

  const [data] = useData(
    gql`
      query GameOverviewPage($gameId: ID!) {
        game(id: $gameId) {
          id
          currentUserDefaultGamePlayer {
            id
          }
          club {
            id
          }
        }
      }
    `,
    { gameId },
  );
  const currentUserDefaultGamePlayerId = data?.game?.currentUserDefaultGamePlayer?.id;
  gaSetSharedEventParams({ club_id: data?.game?.club?.id });

  gamePlayerId ||= currentUserDefaultGamePlayerId;

  const [dataGamePlayer] = useData(
    gql`
      query GameReels($gamePlayerId: ID!) {
        gamePlayer(id: $gamePlayerId) {
          id
          person {
            id
            fullNameWithAnonymisation
          }
          reelViews {
            id
            ...ReelCardFragment
          }
          events {
            id
            eventView {
              id
              ...EventCardFragment
            }
          }
        }
      }
      ${EventCardFragment}
      ${ReelCardFragment}
    `,
    { gamePlayerId },
    { skip: !gamePlayerId },
  );

  if (permissionData && !permissionData.game?.canOnsenAccessVideo) return <NotFoundPage />;
  const gamePlayer = dataGamePlayer?.gamePlayer;

  return (
    <>
      <PageTitle title="Game Overview" />
      <GameOverviewPageHeader
        gameId={gameId}
        gamePlayerId={gamePlayerId}
        gamePlayerIdSet={gamePlayerIdSet}
        tab={tab}
        tabSet={tabSet}
      />
      <PageContentStack>
        {(!tab || tab === "reels") && (
          <Section title={`${gamePlayer?.person.fullNameWithAnonymisation}'s Highlight Reels`}>
            {dataGamePlayer?.gamePlayer.reelViews.length === 0 && (
              <Message>No reels yet! Start tagging events to create your first reel.</Message>
            )}
            <Grid gridItemWidth="250px">
              {dataGamePlayer?.gamePlayer.reelViews.map((reelView) => (
                <ReelCard key={reelView.id} reelView={reelView} />
              ))}
            </Grid>
          </Section>
        )}
        {(!tab || tab === "highlights") && (
          <Section title={`${gamePlayer?.person.fullNameWithAnonymisation}'s Highlights`}>
            {gamePlayer?.events.length <= 5 && (
              <>
                <Message>Let’s get tagging! The more events you tag, the faster you climb up the leaderboard!</Message>
              </>
            )}
            <Grid gridItemWidth="150px">
              {gamePlayer?.events.map((event) => (
                <EventCard key={event.eventView.id} eventView={event.eventView} />
              ))}
              <Paper
                component={ButtonBase}
                href={`/game/${gameId}/video`}
                variant="outlined"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: SPACING_DENSE,
                  gap: SPACING_DENSE,
                }}
              >
                <Plus />
                <div style={{ fontSize: 12, opacity: 0.8 }}>Get Tagging!</div>
              </Paper>
            </Grid>
          </Section>
        )}
        {(!tab || tab === "shotchart") && <ShotChart gameId={gameId} gamePlayerId={gamePlayerId} />}
        {(!tab || tab === "boxscore") && <BoxScore gameId={gameId} gamePlayerId={gamePlayerId} />}
        {(!tab || tab === "pointchart") && <PointChart gameId={gameId} />}
      </PageContentStack>
    </>
  );
}
