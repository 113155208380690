import { gql } from "@apollo/client";
import { COLOR_BLACK } from "helpers/colors";
import useData from "helpers/useData";
import useFullscreenState from "helpers/useFullscreenState";
import Dialog from "molecules/Dialog";
import React from "react";

import HighlightPlayer, { HighlightPlayerFragment } from "./HighlightPlayer";

export default function HighlightPlayerDialog({ open, onClose, eventViewId }) {
  const [data] = useData(
    gql`
      query HighlightPlayerDialog($eventViewId: ID!) {
        eventView(id: $eventViewId) {
          id
          ...HighlightPlayerFragment
        }
      }
      ${HighlightPlayerFragment}
    `,
    { eventViewId },
    { skip: !open },
  );
  const [fullscreen] = useFullscreenState();

  return (
    <Dialog
      noPadding
      open={open}
      onClose={onClose}
      inverse
      {...(fullscreen
        ? { fullHeight: true, fullWidth: true }
        : {
            mediumWidth: true,
          })}
      PaperProps={{
        style: {
          backgroundColor: COLOR_BLACK,
        },
      }}
    >
      <HighlightPlayer eventView={data?.eventView} eventViewId={eventViewId} />
    </Dialog>
  );
}
