export const ONSEN_AUTH_TOKEN_KEY = "gloryleague_onsen_auth_token";
export const PHOTO_CARD_ASPECT_RATIO = 0.7678571428571429; // 215 / 280

export const EVENT_DEFAULT_START_OFFSET_SECONDS = 8;
export const EVENT_DEFAULT_FINISH_OFFSET_SECONDS = 2;
export const EVENT_START_OFFSET_SECONDS_MAX = 16;
export const EVENT_FINISH_OFFSET_SECONDS_MAX = 16;

export const SCHICK_BANNER_ENABLED = false;

export const VIDEO_PAGE_SKIPPING_SECONDS = 3;
