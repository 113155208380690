import { GLORYLEAGUE_URL, ONSEN_APP } from "helpers/environment";
import React from "react";

export default function FooterText() {
  if (ONSEN_APP) return null;
  return (
    <>
      Copyright © {window.YEAR} Glory League Stats
      <a href={`${GLORYLEAGUE_URL}/`} style={{ margin: "0 1em" }}>
        more...
      </a>
    </>
  );
}
