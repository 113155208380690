import { gql } from "@apollo/client";
import { COLOR_BLACK } from "helpers/colors";
import useData from "helpers/useData";
import useFullscreenState from "helpers/useFullscreenState";
import Dialog from "molecules/Dialog";
import React from "react";

import ReelPlayer, { ReelPlayerFragment } from "./ReelPlayer";

export default function ReelPlayerDialog({ open, onClose, reelViewId }) {
  const [data] = useData(
    gql`
      query ReelPlayerDialog($reelViewId: ID!) {
        reelView(id: $reelViewId) {
          id
          ...ReelPlayerFragment
        }
      }
      ${ReelPlayerFragment}
    `,
    { reelViewId },
    { skip: !open },
  );
  const [fullscreen] = useFullscreenState();

  return (
    <Dialog
      noPadding
      open={open}
      onClose={onClose}
      inverse
      {...(fullscreen
        ? { fullHeight: true, fullWidth: true }
        : {
            mediumWidth: true,
          })}
      PaperProps={{
        style: {
          backgroundColor: COLOR_BLACK,
        },
      }}
    >
      <ReelPlayer reelView={data?.reelView} reelViewId={reelViewId} />
    </Dialog>
  );
}
