import { Button } from "@mui/material";
import logoSvg from "assets/logo.svg";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { uiSettingsContext } from "providers/UISettingsProvider";
import React, { createContext, useContext, useState } from "react";

import FooterText from "./FooterText";

const formPageContext = createContext();

export default function FormPage({ title, onSubmit, children }) {
  const [submitting, submittingSet] = useState(false);
  const { isDarkMode } = useContext(uiSettingsContext);

  return (
    <formPageContext.Provider value={{ submitting }}>
      <form
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          backgroundColor: COLOR_WHITE,
          color: COLOR_BLACK,
          ...(isDarkMode && {
            backgroundColor: COLOR_BLACK,
            color: COLOR_WHITE,
          }),
        }}
        onSubmit={async (event) => {
          event.preventDefault();
          submittingSet(true);
          try {
            await onSubmit?.();
          } finally {
            submittingSet(false);
          }
        }}
      >
        <Stack
          padding
          style={{
            maxWidth: 400,
          }}
        >
          <img alt="Glory League Logo" src={logoSvg} style={{ width: 60, margin: "0 auto" }} />
          <div style={{ textAlign: "center", fontSize: 32, fontFamily: FONT_FAMILY_LEAGUE_GOTHIC }}>{title}</div>
          {children}
          <div
            style={{
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            <FooterText />
          </div>
        </Stack>
      </form>
    </formPageContext.Provider>
  );
}

export function FormPageSubmitButton({ label, disabled }) {
  const { submitting } = useContext(formPageContext);
  return (
    <Button fullWidth color="secondary" type="submit" variant="contained" disabled={disabled || submitting}>
      {submitting ? "..." : label}
    </Button>
  );
}
