import { gql } from "@apollo/client";
import TextWithTooltip from "atoms/TextWithTooltip";
import useData from "helpers/useData";
import Section from "molecules/Section";
import StatsTable, {
  StatsTableHeaderCell,
  StatsTableRow,
  StatsTableStatCell,
  StatsTableStatCellFragment,
} from "molecules/StatsTable";
import React from "react";

export default function BoxScore({ gamePlayerId }) {
  const [gamePlayerData] = useData(
    gql`
      query BoxScore_gamePlayer($gamePlayerId: ID!) {
        gamePlayer(id: $gamePlayerId) {
          id
          participation {
            id
            gamePlayers {
              id
              playerNumber
              person {
                id
                shortNameWithAnonymisation
              }
              boxScoreStats {
                shortName
                name
                ...StatsTableStatCellFragment
              }
            }
          }
        }
      }
      ${StatsTableStatCellFragment}
    `,
    { gamePlayerId },
    { skip: !gamePlayerId },
  );

  const firstRow = gamePlayerData?.gamePlayer.participation.gamePlayers[0];

  return (
    <Section title="Box Score">
      <StatsTable
        headerColumns={["Players"]}
        columns={firstRow?.boxScoreStats.map((columnStat) => (
          <TextWithTooltip tooltip={columnStat.name} key={columnStat.shortName}>
            {columnStat.shortName}
          </TextWithTooltip>
        ))}
      >
        {gamePlayerData?.gamePlayer.participation.gamePlayers.map((gamePlayer) => (
          <StatsTableRow key={gamePlayer.id}>
            <StatsTableHeaderCell>
              {gamePlayer.playerNumber} - {gamePlayer.person.shortNameWithAnonymisation}
            </StatsTableHeaderCell>
            {gamePlayer.boxScoreStats.map((stat) => (
              <StatsTableStatCell key={stat.shortName} stat={stat} />
            ))}
          </StatsTableRow>
        ))}
      </StatsTable>
    </Section>
  );
}
